import React, { useState } from 'react';
import { Alert, Table } from '../../shared/components';
import { TimeActivityCodeApi } from '../../shared/services';

export default function AddActivityCode({ ptoTypes, timeActivityCodes, setTimeActivityCodes, timeTaskCodes }) {
    const [displayAlert, setDisplayAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertStyle, setAlertStyle] = useState("info");
    const [disableAddButton, setDisableAddButton] = useState(false);
    
    const handleSubmit = (data, setData) => {
        setDisableAddButton(true);
        //Check required fields
        if(data.TimeActivityCodeNM !== "" && data.TimeActivityCodeDSC !== "" && data.PTOType !== null) {
            
            const myTimeActivityCodeApi = new TimeActivityCodeApi();
            myTimeActivityCodeApi.addTimeActivityCode({
                TimeActivityCodeNM: data.TimeActivityCodeNM,
                TimeActivityCodeDSC: data.TimeActivityCodeDSC,
                //If the rate is left blank, change it to null before sending to backend.
                TimeActivityRateNBR: data.TimeActivityRateNBR === "" ? null : data.TimeActivityRateNBR,
                TimeTaskCodeID: data.TimeTaskCode?.TimeTaskCodeID,
                PTOTypeID: data.PTOType.PTOTypeID,

            }).then(newActivityCode => {
                //Update Activity Code table new newly created activity code.
                const timeActivityCodesCopy = [...timeActivityCodes];
                const dataCopy = {...data};
                //Add the ID and ActiveFLG created for the new Activity Code.
                dataCopy.TimeActivityCodeID = newActivityCode.TimeActivityCodeID;
                dataCopy.TimeActivityActiveFLG = newActivityCode.TimeActivityActiveFLG;
                //Push the Activity Code onto the copy.
                timeActivityCodesCopy.push(dataCopy);
                //Update state of Activity Codes with adjusted copy.
                setTimeActivityCodes(timeActivityCodesCopy);
                //notify user of successful add
                setAlertMessage(`The Activity Code with Code Name: ${newActivityCode.TimeActivityCodeNM} was successfully added`);
                setAlertStyle("info");
                setDisplayAlert(true);
                //Clear fields
                const dataCopy2 = {...data};
                dataCopy2.TimeActivityCodeNM = "";
                dataCopy2.TimeActivityCodeDSC = "";
                dataCopy2.TimeActivityRateNBR = "";
                dataCopy2.TimeTaskCode = null;
                dataCopy2.PTOType = null;
                setData(dataCopy2);
                setDisableAddButton(false);
            });
        } else {
            setAlertMessage("Please fill out Code, Description, and PTO Type Fields as they are required.");
            setAlertStyle("warning");
            setDisplayAlert(true);
            setDisableAddButton(false);
        }
    }
    const columns = [
        {
            header: "CODE", 
            dataKey: "TimeActivityCodeNM", 
            editable: true, 
            type: "string"
        },
        {
            header: "DESCRIPTION", 
            dataKey: "TimeActivityCodeDSC", 
            editable: true, 
            type: "string"
        },
        {
            header: "RATE", 
            dataKey: "TimeActivityRateNBR", 
            editable: true, 
            type: "string"
        },
        {
            header: "TASK CODE", 
            dataKey: "TimeTaskCode", 
            editable: true, 
            type: "object-dropdown", 
            itemNameKey: "TimeTaskCodeDepartmentNM", 
            options: timeTaskCodes,
            label: "Task Code"
        },
        {
            header: "PTO TYPE", 
            dataKey: "PTOType", 
            editable: true, 
            type: "object-dropdown", 
            itemNameKey: "PTOTypeDSC", 
            options: ptoTypes,
            label: "PTO Type"
        },
        {
            header: "SUBMIT", 
            dataKey: "1", 
            editable: false, 
            type: "add-button",
            function: handleSubmit,
            disableAddButton: disableAddButton
        },
    ]

    const rowsData = [
        {id: "1", TimeActivityCodeNM: "", TimeActivityCodeDSC: "", TimeActivityRateNBR: "", TimeTaskCode: null, PTOType: null}
    ]
    return (
        <>
            <Alert 
                displayAlert={displayAlert}
                setDisplayAlert={setDisplayAlert}
                style={alertStyle}
                message={alertMessage}
            />
            <Table
                columns={columns}
                rowsData={rowsData}
                primaryKey="id"
                isAdd={true}
            /> 
        </>
    )
}
