import React, {useState} from 'react';
import { Alert, Table } from '../../shared/components';
import { TimeTaskCodeApi } from '../../shared/services';

export default function AddTaskCode({ taskCodes, setTaskCodes}) {
    const [displayAlert, setDisplayAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertStyle, setAlertStyle] = useState("info");
    const [disableAddButton, setDisableAddButton] = useState(false);

    const handleSubmit = (data, setData) => {
        setDisableAddButton(true);
        setDisplayAlert(false);
        if(data.TimeTaskCodeNM !== "" && data.TimeTaskCodeDepartmentNM !== "") {
            const myTimeTaskCodeApi = new TimeTaskCodeApi();
            myTimeTaskCodeApi.addTimeTaskCode({
                TimeTaskCodeDepartmentNM: data.TimeTaskCodeDepartmentNM,
                TimeTaskCodeNM: data.TimeTaskCodeNM
            }).then(newTaskCode => {
                const taskCodesCopy = [...taskCodes];
                taskCodesCopy.push(newTaskCode);
                setTaskCodes(taskCodesCopy);
                //Notify user of success.
                setAlertMessage(`The Task Code with Code Name: ${newTaskCode.TimeTaskCodeNM} was created.`);
                setAlertStyle("info");
                setDisplayAlert(true);
                //Clear fields
                const dataCopy = {...data};
                dataCopy.TimeTaskCodeDepartmentNM = "";
                dataCopy.TimeTaskCodeNM = "";
                setData(dataCopy);
                setDisableAddButton(false);
            });
        } else {
            setAlertMessage("Please fill out both fields as they are both required.");
            setAlertStyle("warning");
            setDisplayAlert(true);
            setDisableAddButton(false);
        }
    }

    const columns = [
        {header: "CODE", dataKey: "TimeTaskCodeNM", editable: true, type: "string"},
        {header: "DEPARTMENT", dataKey: "TimeTaskCodeDepartmentNM", editable: true, type: "string"},
        {header: "SUBMIT", dataKey: "1", editable: false, type: "add-button", function: handleSubmit, disableAddButton: disableAddButton}
    ];

    const rowsData = [
        {id: "1", TimeTaskCodeNM: "", TimeTaskCodeDepartmentNM: ""}
    ]

    return (
        <>
            <Alert 
                displayAlert={displayAlert} 
                setDisplayAlert={setDisplayAlert} 
                style={alertStyle} 
                message={alertMessage}
            />
            <Table 
                columns={columns}
                rowsData={rowsData}
                primaryKey="id"
                isAdd={true}
            />
        </>
    )
}
