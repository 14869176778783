import React from 'react';
import { Switch, Route, Redirect} from 'react-router-dom';//React Router Dom components. Handles server side routing
import { Navbar } from "../shared/components";  //Navigation bar for app
import { useAuth } from "../context/auth-context";
import Home from '../views/home/home';
import Dashboard from '../views/dashboard/Dashboard';
import Admin from "../views/admin/admin";
import Supervisor from '../views/supervisor/supervisor';

export default function Transitions() {
    //Switch and Route gives access to the different pages of the app without need to load in next page
    return (
        <>
            <Navbar/>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/dashboard" component={Dashboard} />
                <PrivateAdminRoute path="/admin">
                    <Admin/>
                </PrivateAdminRoute>
                <PrivateSupervisorRoute path="/supervisor">
                    <Supervisor/>
                </PrivateSupervisorRoute>
            </Switch>     
        </>
    )
}

const PrivateAdminRoute = ({ children, ...rest}) => {
    const { isAdmin } = useAuth();

    return (
        <Route {...rest} render={({location}) => 
            isAdmin() ? (children) : (
            <Redirect to={{pathname: '/', state: {from: location}}} />
            )
        }/>
    )
}

const PrivateSupervisorRoute = ({ children, ...rest}) => {
    const { isAdmin, isSupervisor } = useAuth();

    return (
        <Route {...rest} render={({location}) => 
            isSupervisor() || isAdmin() ? (children) : (
            <Redirect to={{pathname: '/', state: {from: location}}} />
            )
        }/>
    )
}
