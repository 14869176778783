import React from 'react';
import "./time-picker.css";
import { timeIcon } from '../../../assets/images/images';

export default function TimePicker({fields, handleFieldEdit, hourKey, minuteKey, periodKey}) {

    const handleHourChange = (input) => {
        handleFieldEdit(input, hourKey);
    }

    const handleHourLeave = (input) => {
        if(parseInt(input) <= 0) {
            handleFieldEdit("01", hourKey);
        } else if(parseInt(input) > 12) {
            handleFieldEdit("12", hourKey);
        } else if(input.length === 1) {
            handleFieldEdit(`0${input}`, hourKey);
        } else{
            handleFieldEdit(input, hourKey);
        }
    }

    const handleMinChange = (input) => {
        if(input.length === 2 && (parseInt(input) < 8 || parseInt(input) > 52)) {
            handleFieldEdit("00", minuteKey);
        } else if(input.length === 2 && parseInt(input) < 22) {
            handleFieldEdit("15", minuteKey);
        } else if(input.length === 2 && parseInt(input) < 37) {
            handleFieldEdit("30", minuteKey);
        } else if(input.length === 2 && parseInt(input) <= 52) {
            handleFieldEdit("45", minuteKey);
        } else if(input.length === 1 || input.length === 0)  {
            handleFieldEdit(input, minuteKey);
        }
    }

    //Occurs specifically when the user leaves 
    const handleMinLeave = (input) => {
        if(input.length === 1 && parseInt(input) === 0) {
            handleFieldEdit("00", minuteKey);
        } else if(input.length === 1 && parseInt(input) === 1) {
            handleFieldEdit("15", minuteKey);
        } else if(input.length === 1 && (parseInt(input) === 2 || parseInt(input) === 3)) {
            handleFieldEdit("30", minuteKey);
        } else if(input.length === 1 && (parseInt(input) === 4 || parseInt(input) === 5)) {
            handleFieldEdit("45", minuteKey);
        } else if(input.length === 1 || input.length === 0){
            handleFieldEdit("00", minuteKey);
        }
    }

    const handlePeriodChange = (input) => {
        handleFieldEdit(input, periodKey);
    }

    const handlePeriodLeave = (input) => {
        if(input?.toLowerCase() === "am" || input[0]?.toLowerCase() === "a") {
            handleFieldEdit("AM", periodKey);
        } else {
            handleFieldEdit("PM", periodKey);
        }
    }

    return (
        <div className="time-picker">
            <div className="form-control time-form">
                <input
                    type="number" 
                    className="sub-form sub-form-hour" 
                    placeholder="--" 
                    min="1" 
                    max="12"
                    value={fields[hourKey]}
                    onChange={({target}) => handleHourChange(target.value)}
                    onBlur={({target}) => handleHourLeave(target.value)}
                />
                {":"}
                <input 
                    type="number" 
                    className="sub-form sub-form-min" 
                    placeholder="--"
                    value={fields[minuteKey]}
                    onChange={({target}) => handleMinChange(target.value)}
                    onBlur={({target}) => handleMinLeave(target.value)}
                />
                <input 
                    type="text" 
                    className="sub-form sub-form-period" 
                    placeholder="--"
                    value={fields[periodKey]}
                    onChange={({target}) => handlePeriodChange(target.value)}
                    onBlur={({target}) => handlePeriodLeave(target.value)}
                />
                <img src={timeIcon} alt="select time" className="time-icon m-auto"/>
            </div>
        </div>
    )
}
