import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Link } from 'react-router-dom';

export function LoginButton() {
    const { instance } = useMsal();

    const handleLogin = async () => {
        await instance.handleRedirectPromise();
        instance.loginRedirect();
    }

    return (
        <Link className="nav-link login-button navhover" to="#" onClick={() => handleLogin()}>
            LOGIN
        </Link>
    )
}

export function LogoutButton() {
const { instance } = useMsal();

const handleLogout = async () => {
    await instance.handleRedirectPromise();
    instance.logoutRedirect();
}

return (
    <Link className="login-button nav-link" to="#" onClick={() => handleLogout()}>
            LOG OUT
    </Link>
)
}