import React, { useState } from 'react';
import { Alert, Table } from '../../shared/components';
import { dateTimePickerToDB, hourDifference, timeToMilitaryTime } from '../../utils/date-format';
import { TimePunchApi } from '../../shared/services';
import { useAuth } from '../../context/auth-context';

export default function AddTime({ timeActivityCodes, employee, payPeriod, timePunches, setTimePunches }) {
    const [disableAddButton, setDisableAddButton] = useState(false);
    const [displayAlert, setDisplayAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertStyle, setAlertStyle] = useState("info");
    const { currentUser } = useAuth();

    const handleSubmit = (data, setData) => {
        setDisableAddButton(true);
        if(data.inDate !== "" && data.inHour !== "" && data.inMinute !== "" && data.inPeriod !== "" && data.outDate !== "" 
            && data.outHour !== "" && data.outMinute !== "" && data.outPeriod !== "" && data.ActivityCode !== null) {
            const inTime = timeToMilitaryTime(data.inHour, data.inMinute, data.inPeriod);
            const outTime = timeToMilitaryTime(data.outHour, data.outMinute, data.outPeriod);
            //Check the hours difference between the punch in and punch out. If positive, proceed. Otherwise notify user that negative or zero differences are not allowed.
            const hoursDiff = hourDifference(data.inDate, inTime, data.outDate, outTime);
            if(hoursDiff > 0) {
                const myTimePunchApi = new TimePunchApi();
                myTimePunchApi.addTimePunch({
                    PersonID: employee.PersonID,
                    TimeInDTS: dateTimePickerToDB(data.inDate, inTime),
                    TimeOutDTS: dateTimePickerToDB(data.outDate, outTime),
                    TimeActivityCodeID: data.ActivityCode?.TimeActivityCodeID,
                    CurrentUser: currentUser
                }).then(newTimePunch => {
                    if(newTimePunch.error) {
                        setAlertMessage(`The time punch was not recorded. Cause: ${newTimePunch.error} Please talk to your administrator.`);
                        setAlertStyle("danger");
                        setDisplayAlert(true);
                        setDisableAddButton(false);
                    } else {
                        //If time punches are being displayed, add new time punch to list of time punches.
                        if(payPeriod?.PayPeriodID === newTimePunch.PayPeriodID) {
                            const timePunchesCopy = [...timePunches];
                            timePunchesCopy.push(newTimePunch);
                            setTimePunches(timePunchesCopy);
                        }
    
                        //Notify user of successful add
                        setAlertMessage(`The time punch ${newTimePunch.InDate} ${newTimePunch.InTime} to ${newTimePunch.OutDate} ${newTimePunch.OutTime} has been recorded.`);
                        setAlertStyle("info");
                        setDisplayAlert(true);
                        //Clear fields
                        const dataCopy = {...data};
                        dataCopy.inDate = "";
                        dataCopy.inHour = "";
                        dataCopy.inMinute = "";
                        dataCopy.inPeriod = "";
                        dataCopy.outDate = "";
                        dataCopy.outHour = "";
                        dataCopy.outMinute = "";
                        dataCopy.outPeriod = "";
                        dataCopy.ActivityCode = null;
                        setData(dataCopy);
                        setDisableAddButton(false);
                    }
                });
            } else {
                setAlertMessage("You cannot clock in zero or negative hours. Please adjust your time punch to start time to begin before your end time.");
                setAlertStyle("warning");
                setDisplayAlert(true);
                setDisableAddButton(false);
            }
        } else {
            setAlertMessage("Please fill Date In, Date Out, Time in, Time Out, and select an Activity Code before submitting your time.");
            setAlertStyle("warning");
            setDisplayAlert(true);
            setDisableAddButton(false);
        }
    }

    const columns = [
        {
            header: "DATE IN",
            dataKey: "inDate",
            editable: true,
            type: "date",
            alsoEdit: "outDate"
        },
        {
            header: "TIME IN",
            dataKey: "inTime",
            hourKey: "inHour",
            minuteKey: "inMinute",
            periodKey: "inPeriod",
            editable: true,
            type: "time",
            step: "900"
        },
        {
            header: "DATE OUT",
            dataKey: "outDate",
            editable: true,
            type: "date"
        },
        {
            header: "TIME OUT",
            dataKey: "outTime",
            hourKey: "outHour",
            minuteKey: "outMinute",
            periodKey: "outPeriod",
            editable: true,
            type: "time"
        },
        {
            header: "HOURS",
            dataKey: "hours",
            editable: false,
            type: "date-time-compare",
            dateCompare1: "inDate",
            timeCompare1: "inTime",
            hourCompare1: "inHour",
            minuteCompare1: "inMinute",
            periodCompare1: "inPeriod",
            dateCompare2: "outDate",
            timeCompare2: "outTime",
            hourCompare2: "outHour",
            minuteCompare2: "outMinute",
            periodCompare2: "outPeriod",
        },
        {
            header: "ACTIVITY CODE",
            dataKey: "ActivityCode",
            editable: true,
            type: "object-dropdown",
            itemNameKey: "TimeActivityCodeDSC",
            options: timeActivityCodes,
            label: "Activity Code"
        },
        {
            header: "SUBMIT", 
            dataKey: "1", 
            editable: false, 
            type: "add-button",
            function: handleSubmit,
            disableAddButton: disableAddButton
        }
    ];

    const rowsData = [
        {
            id: "1",
            inDate: "",
            inTime: "",
            inHour: "",
            inMinute: "",
            inPeriod: "",
            outDate: "", 
            outTime: "",
            outHour: "",
            outMinute: "",
            outPeriod: "",
            hours: 0,
            ActivityCode: null
        }
    ]

    return (
        <>
            <Alert 
                displayAlert={displayAlert} 
                setDisplayAlert={setDisplayAlert} 
                style={alertStyle} 
                message={alertMessage}
            />
            <Table
                columns={columns}
                rowsData={rowsData}
                primaryKey="id"
                isAdd={true}
            />
        </>
    )
}
