import React, { useEffect, useState} from 'react';
import { Table } from '../../shared/components';
import { EmployeeTypeApi, PersonApi, PersonTypeApi, ProgramApi, TimeActivityCodeApi } from '../../shared/services';

export default function EmployeeTable() {
    const [employees, setEmployees] = useState([]);
    const [supervisors, setSupervisors] = useState([]);
    const [employeeTypes, setEmployeeTypes] = useState([]);
    const [personTypes, setPersonTypes] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [timeActivityCodes, setTimeActivityCodes] = useState([]);

    useEffect(() => {
        const myPersonApi = new PersonApi();
        myPersonApi.getAllEmployees()
            .then(employeeList => {
                setEmployees(employeeList);
            });

        myPersonApi.getSupervisors()
            .then(supervisorList => {
                setSupervisors(supervisorList);
            });
        
        const myPersonTypeApi = new PersonTypeApi();
        myPersonTypeApi.getPersonTypes()
            .then(typeList => {
                setPersonTypes(typeList);
            });

        const myEmployeeTypeApi = new EmployeeTypeApi();
        myEmployeeTypeApi.getEmployeeTypes()
            .then(typeList => {
                setEmployeeTypes(typeList);
            })

        const myTimeActivityCodeApi = new TimeActivityCodeApi();
        myTimeActivityCodeApi.getActiveTimeActivityCodes()
            .then(activityCodeList => {
                setTimeActivityCodes(activityCodeList);
            });
        
        const myProgramApi = new ProgramApi();
        myProgramApi.getPrograms()
            .then(programList => {
                setPrograms(programList);
            })
    }, []);

    const handleEdit = (data) => {
        console.log(data);
        const myPersonApi = new PersonApi();
        myPersonApi.editEmployee({
            PersonID: data.PersonID,
            FirstNM: data.FirstNM,
            LastNM: data.LastNM,
            EmailTXT: data.EmailTXT,
            TimeCardPersonTypeID: data.TimeCardPersonType ? data.TimeCardPersonType.PersonTypeID : null,
            EmployeeTypeDSC: data.EmployeeType ? data.EmployeeType.EmployeTypeDSC : null,
            EmployeeTypeID: data.EmployeeType ? data.EmployeeType.EmployeeTypeID : null,
            EmployeePayrollCodeNM: data.EmployeePayrollCodeNM,
            HoursPerWeekMaxNBR: data.HoursPerWeekMaxNBR,
            SupervisorPersonID: data.SupervisorPerson ? data.SupervisorPerson.PersonID : null,
            AssignedProgramID: data.AssignedProgram ? data.AssignedProgram.ProgramID : null,
            ExemptFLG: data.ExemptFLG,
            TimePunchActiveFLG: data.TimePunchActiveFLG,
            TimeActivityCodes: data.TimeActivityCodes
        }).then(updatedEmployee => {
            console.log(updatedEmployee);
        });
    }

    const columns = [
        {
            header: "FIRST NAME",
            dataKey: "FirstNM",
            editable: true,
            type: "string"
        },
        {
            header: "LAST NAME",
            dataKey: "LastNM",
            editable: true,
            type: "string"
        },
        {
            header: "USER PRINCIPAL NAME",
            dataKey: "EmailTXT",
            editable: true,
            type: "string"
        },
        {
            header: "EMPLOYEE TYPE",
            dataKey: "EmployeeType",
            editable: true,
            type: "object-dropdown",
            itemNameKey: "EmployeeTypeDSC",
            options: employeeTypes,
            label: "Employee Type",
            alsoEdit: "HoursPerWeekMaxNBR",
            withValue: "EmployeeTypeDefaultHoursPerWeekMaxNBR"
        },
        {
            header: "PAYROLL CODE NAME",
            dataKey: "EmployeePayrollCodeNM",
            editable: true,
            type: "name-code",
            firstName: "FirstNM",
            lastName: "LastNM"
        },
        {
            header: "MAX HOURS",
            dataKey: "HoursPerWeekMaxNBR",
            editable: true,
            type: "number"
        },
        {
            header: "SUPERVISOR",
            dataKey: "SupervisorPerson",
            editable: true,
            type: "object-dropdown",
            itemNameKey: "FirstNM",
            itemNameSecondKey: "LastNM",
            options: supervisors,
            label: "Supervisor"
        },
        {
            header: "ROLE",
            dataKey: "TimeCardPersonType",
            editable: true,
            type: "object-dropdown",
            itemNameKey: "PersonTypeDSC",
            options: personTypes,
            label: "Role"
        },
        {
            header: "ACTIVITY CODES",
            dataKey: "TimeActivityCodes",
            editable: true,
            type: "object-multi-select",
            itemNameKey: "TimeActivityCodeDSC",
            itemPrimaryKey: "TimeActivityCodeID",
            options: timeActivityCodes,
            label: "Activity Code"
        },
        {
            header: "ASSIGNED PROGRAM",
            dataKey: "AssignedProgram",
            editable: true,
            type: "object-dropdown",
            itemNameKey: "ProgramNM",
            options: programs,
            label: "Assigned Program"
        },
        {
            header: "EXEMPT",
            dataKey: "ExemptFLG",
            editable: true,
            type: "boolean",
        },
        {
            header: "ACTIVE",
            dataKey: "TimePunchActiveFLG",
            editable: true,
            type: "boolean",
        },
        {
            header: "EDIT", 
            dataKey: "PersonID", 
            editable: false, 
            type: "edit-button",
            function: handleEdit
        }
    ]

    const toggleData = {
        toggleLabel: "Display Active Only",
        dataKey: "TimePunchActiveFLG",
        filterIfEquals: 1
    }

    const searchableColumns = [
        {
            dataKey: "FirstNM",
            type: "string"
        },
        {
            dataKey: "LastNM",
            type: "string"
        },
        {
            dataKey: "EmailTXT",
            type: "string"
        },
        {
            dataKey: "EmployeeType",
            type: "object",
            subDataKey: "EmployeeTypeDSC",
        },
        {
            dataKey: "EmployeePayrollCodeNM",
            type: "string"
        },
        {
            dataKey: "HoursPerWeekMaxNBR",
            type: "number"
        },
        {
            dataKey: "SupervisorPerson",
            subDataKey: "LastNM",
            type: "object"
        },
        {
            dataKey: "TimeCardPersonType",
            subDataKey: "PersonTypeDSC",
            type: "object"
        },
        {
            dataKey: "AssignedProgram",
            subDataKey: "ProgramNM",
            type: "object"
        }
    ]

    return (
        <>
            <Table
                columns={columns}
                rowsData={employees}
                primaryKey="PersonID"
                isAdd={false}
                toggleData={toggleData}
                searchableColumns={searchableColumns}
                scrollable={true}
                height="600"
            />
        </>
    )
}