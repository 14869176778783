import React, { useEffect, useState } from 'react';
import { ObjectDropdown } from '../../shared/components';
import { PayPeriodApi } from '../../shared/services';
import UnapprovedPunches from './unapproved-punch-supervisors';

export default function LockPayPeriod() {
    const [payPeriods, setPayPeriods] = useState([]);
    const [payPeriod, setPayPeriod] = useState(null);

    useEffect(() => {
        const myPayPeriodApi = new PayPeriodApi();
        myPayPeriodApi.getPayPeriods()
            .then(payPeriodList => {
                setPayPeriods(payPeriodList);
            });
    }, []);

    const handleClick = () => {
        if(payPeriod) {
            const myPayPeriodApi = new PayPeriodApi();
            myPayPeriodApi.toggleLock({
                PayPeriodID: payPeriod.PayPeriodID,
                PayPeriodLockedFLG: !payPeriod.PayPeriodLockedFLG
            }).then(updatedPayPeriod => {
                const payPeriodsCopy = [...payPeriods];
                payPeriods.forEach((payPeriodCopy, index) => {
                    if(payPeriodCopy.PayPeriodID === updatedPayPeriod.PayPeriodID) {
                        payPeriodCopy.PayPeriodLockedFLG = updatedPayPeriod.PayPeriodLockedFLG;
                    }
                });
                setPayPeriods(payPeriodsCopy);
            })
        }
    }

    return (
        <div className="mx-4 mt-2">
            <div className="row">
                <div className="col col-lg-2">
                    <ObjectDropdown 
                        items={payPeriods}
                        itemNameKey="PayPeriodStartDTSPretty"
                        itemNameDivider=" - "
                        itemNameSecondKey="PayPeriodEndDTSPretty"
                        selected={payPeriod}
                        setSelected={setPayPeriod}
                        label="Pay Period"
                        isBig={true}
                        searchable={true}
                    />
                </div>
                <div className="col col-lg-2 mt-2">
                    {payPeriod ? payPeriod.PayPeriodLockedFLG ? 
                        "Locked"
                        : "Unlocked" 
                        : "Select a Pay Period"}
                </div>
                <div className="col col-lg-2">
                    <button className="btn btn-primary" onClick={handleClick} disabled={!payPeriod}>
                        {payPeriod?.PayPeriodLockedFLG === true ? "Unlock Pay Period" : "Lock Pay Period"}
                    </button>
                </div>
            </div>
            <UnapprovedPunches payPeriod={payPeriod}/>
        </div>
    )
}
