import React, {useEffect, useState} from 'react';
import "./home.css"
import { ObjectDropdown } from '../../shared/components';
import AddTime from "../log-time/add-time";
import TimePunchTable from '../log-time/time-punch-table';
import TimeStatus from './time-status';
import { OvertimeApi, PayPeriodApi, TimeActivityCodeApi } from '../../shared/services';
import { currentDateToDB } from '../../utils/date-format';
import { useAuth } from "../../context/auth-context";

export default function Home() {
    const [timeActivityCodes, setTimeActivityCodes] = useState([]);
    const { currentUser } = useAuth();
    const [payPeriods, setPayPeriods] = useState([]);
    const [payPeriod, setPayPeriod] = useState(null);
    const [timePunches, setTimePunches] = useState([]);
    const [payPeriodReport, setPayPeriodReport] = useState(null);
    const [weekReport, setWeekReport] = useState(null);
    const [showDateRange, setShowDateRange] = useState(false);
    const [dateRangeForm, setDateRangeForm] = useState({ start: "", end: "" });
    const [displayedTotalHours, setDisplayedTotalHours] = useState(0);

    useEffect(() => {
        const myPayPeriodApi = new PayPeriodApi();
        myPayPeriodApi.getPayPeriods()
            .then(payPeriodList => {
                setPayPeriods(payPeriodList);
                const firstPayPeriod = payPeriodList ? payPeriodList[0] : null;
                setPayPeriod(firstPayPeriod);
            });
    }, []);

    /// convert timePunches to a total hours value
    useEffect(() => {
        let totalHours = 0;
        timePunches.forEach(timePunch => {
            totalHours += timePunch.hours;
        });

        setDisplayedTotalHours(totalHours);
    }, [timePunches]);

    useEffect(() => {
        if(currentUser) {
            const myTimeActivityCodeApi = new TimeActivityCodeApi();
            myTimeActivityCodeApi.getEmployeeTimeActivityCodes(currentUser.PersonID)
                .then(activityCodes => {
                    setTimeActivityCodes(activityCodes);
                });

            const myOvertimeApi = new OvertimeApi();
            myOvertimeApi.getEmployeeWeekOvertime(currentUser.PersonID, currentDateToDB())
                .then(weekOvertime => {
                    setWeekReport(weekOvertime);
                });
            if(payPeriod) {
                myOvertimeApi.getEmployeePayPeriodOvertime(currentUser.PersonID, payPeriod.PayPeriodID)
                    .then(payPeriodOvertime => {
                        setPayPeriodReport(payPeriodOvertime);
                    });
            } else if (payPeriod === "date range") {
                setShowDateRange(true);
            }
        }
    }, [currentUser, payPeriod, timePunches]);

    const dateRange = showDateRange ?
        <div style={{ maxWidth: 200}}>
            <div className="input-group my-1">
                <span className="input-group-text" id="basic-addon3">Start</span>
                <input type="date" className="form-control" aria-describedby="basic-addon3" value={dateRangeForm.start} onChange={(e) => setDateRangeForm(prev => ({ ...prev, start: e.target.value }))} />
            </div>
            <div className="input-group my-1">
                <span className="input-group-text" id="basic-addon3">End</span>
                <input type="date" className="form-control" aria-describedby="basic-addon3" value={dateRangeForm.end} onChange={(e) => setDateRangeForm(prev => ({ ...prev, end: e.target.value }))} />
            </div>
        </div> : ""

    return (
        <div className="mx-4 mt-2">
            <TimeStatus 
                totalHours={displayedTotalHours}
                overtime={weekReport ? weekReport.OverTimeHours : 0}
                weekHours={weekReport ? weekReport.TotalHours : 0}
                showDateRange={showDateRange}
                showPayPeriod={payPeriod !== null}
                weekMaxHours={weekReport ? weekReport.MaxWeeklyHours : 0}
            />
            <ObjectDropdown 
                items={payPeriods}
                itemNameKey="PayPeriodStartDTSPretty"
                itemNameDivider=" - "
                itemNameSecondKey="PayPeriodEndDTSPretty"
                selected={payPeriod}
                setSelected={setPayPeriod}
                label="Pay Period"
                isBig={true}
                selectAll={true}
                searchable={true}
                dateRange={true}
                setDateRangeSelected={setShowDateRange}
            />
            {dateRange}
            
            <h5 className="title">Add Time {currentUser ? "for " + currentUser.FirstNM + " " + currentUser.LastNM : ""}</h5>
            <AddTime 
                timeActivityCodes={timeActivityCodes} 
                employee={currentUser}
                payPeriod={payPeriod}
                timePunches={timePunches}
                setTimePunches={setTimePunches}
            />
            <h5 className="title">Edit Time</h5>
            <TimePunchTable 
                timeActivityCodes={timeActivityCodes} 
                employee={currentUser} 
                payPeriod={payPeriod}
                timePunches={timePunches}
                setTimePunches={setTimePunches}
                downloadable={true}
                allowDelete={true}
                dateRange={showDateRange && dateRangeForm.start !== "" && dateRangeForm.end !== "" ? dateRangeForm : false}
            />
        </div>
    )
}
