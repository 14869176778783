import React, { useEffect, useState } from 'react';

/**
 * React function component Alert
 * @param {bool} displayAlert: a state variable describing whether the Alert should display or not.
 * @param {function} setDisplayAlert: a useState hook that changes the state of displayAlert. 
 * @param {string} style: a string that determines the color of the alert. Using 'info' makes the alert green
 * and using 'warning' makes it yellow. Any other string value makes it red (bootstrap danger color).
 * @param {string} message: the string message to display within the alert.
 * @returns an alert component
 */
export default function Alert({displayAlert, setDisplayAlert, style, message}) {
    const [alertStyle, setAlertStyle] = useState("");

    useEffect(() => {
        if(style === "info") {
            setAlertStyle("alert alert-info alert-dismissible");
        } else if(style === "warning") {
            setAlertStyle("alert alert-warning alert-dismissible");
        } else {
            setAlertStyle("alert alert-danger alert-dismissible");
        }
    }, [style]);

    if(displayAlert) {
        return (
            <div className={alertStyle} role="alert">
                {message}
                <button type="button" className="btn-close" onClick={() => setDisplayAlert(false)} aria-label="Close"></button>
            </div>
        )
    } else {
        return (null);
    }
}
