import React, { useEffect, useState } from 'react';
import TaskCodeTable from './task-code-table';
import AddTaskCode from './add-task-code';
import { TimeTaskCodeApi } from '../../shared/services';

export default function TaskCodes() {
    const [timeTaskCodes, setTimeTaskCodes] = useState([]);

    useEffect(() => {
        const myTimeTaskCodeApi = new TimeTaskCodeApi();
        myTimeTaskCodeApi.getAllTimeTaskCodes()
            .then(taskCodesList => {
                setTimeTaskCodes(taskCodesList);
            });
    }, []);

    return (
        <div className="mx-4 mt-2">
            <h5 className="title">Add Task Code</h5>
            <AddTaskCode taskCodes={timeTaskCodes} setTaskCodes={setTimeTaskCodes}/>
            <h5 className="title">Task Codes</h5>
            <TaskCodeTable timeTaskCodes={timeTaskCodes} setTimeTaskCodes={setTimeTaskCodes}/>
        </div>
    )
}
