import React from 'react';
import { Table } from '../../shared/components';
import { TimeTaskCodeApi } from '../../shared/services';

export default function TaskCodeTable({ timeTaskCodes, setTimeTaskCodes}) {

    const handleEdit = (data) => {
        const myTimeTaskCodeApi = new TimeTaskCodeApi();
        myTimeTaskCodeApi.editTimeTaskCode(data)
            .then(newTaskCode => {
                const timeTaskCodesCopy = [...timeTaskCodes];
                timeTaskCodesCopy.forEach((taskCode, index) => {
                    if(taskCode.TimeTaskCodeID === newTaskCode.TimeTaskCodeID) {
                        timeTaskCodesCopy[index] = newTaskCode;
                    }
                });
                setTimeTaskCodes(timeTaskCodesCopy);
            })
    }

    const columns = [
        {header: "CODE", dataKey: "TimeTaskCodeNM", editable: true, type: "string"},
        {header: "DEPARTMENT", dataKey: "TimeTaskCodeDepartmentNM", editable: true, type: "string"},
        {header: "ACTIVE", dataKey: "TimeTaskCodeActiveFLG", editable: true, type: "boolean"},
        {header: "EDIT", dataKey: "TimeTaskCodeID", editable: false, type: "edit-button", function: handleEdit}
    ]

    const toggleData = {
        toggleLabel: "Display Active Only",
        dataKey: "TimeTaskCodeActiveFLG",
        filterIfEquals: true
    }

    const searchableColumns = [
        {
            dataKey: "TimeTaskCodeNM",
            type: "string"
        },
        {
            dataKey: "TimeTaskCodeDepartmentNM",
            type: "string"
        }
    ];

    return (
        <>
            <Table
                columns={columns}
                rowsData={timeTaskCodes}
                setRowsData={setTimeTaskCodes}
                primaryKey="TimeTaskCodeID"
                isAdd={false}
                toggleData={toggleData}
                searchableColumns={searchableColumns}
                scrollable={true}
                height="500"
            />
        </>
    )
}
