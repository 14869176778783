import React from 'react';
import { editIcon, saveIcon } from '../../../assets/images/images';
import "./edit-button.css";

export default function EditButton({ handleEdit, editable, setEditable, data, disabled }) {
    const saveEdit = () => {
        handleEdit(data);
        setEditable(false);
    }

    if(editable) {
        return (
            <button className="btn" onClick={saveEdit} disabled={disabled}>
                <img className="edit" alt="edit" src={saveIcon}/>
            </button>
        )
    } else {
        return (
            <button className="btn" onClick={() => setEditable(true)} disabled={disabled}>
                <img className="edit" alt="edit" src={editIcon}/>
            </button>
        )

    }
}
