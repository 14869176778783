import {graphConfig, loginRequest} from "../config/auth-config"; //Allows us to use the token created when a user logs in
import {msalInstance} from "./msal-instance"; //Allows us to hook into the connection instance

//Function callMsGraph
//returns graph data of the currently logged in user or throws an error if there is no logged in user.
export async function callMsGraph() {
    const account = msalInstance.getActiveAccount();    //Grab the logged in user
    if (!account) { //Throws error if no user is logged in and set as the active account.
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    //Grab the token
    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,    //{scopes: ["User.Read"]]}
        account: account
    });

    //setup request header and add token to header
    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;
    headers.append("Authorization", bearer);

    //setup fetch options for a get request and add header with token
    const options = {
        method: "GET",
        headers: headers
    };

    //GraphMeEndpoint is the fetch URI for getting the graph data for the logged in user.
    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())  //Make the data a readable object
        .catch(error => console.log(error));    //Console log any errors thrown
}

export async function getIDToken() {
    const account = msalInstance.getActiveAccount();    //Grab the logged in user
    if (!account) { //Throws error if no user is logged in and set as the active account.
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,    //{scopes: ["User.Read"]]}
        account: account
    });

    return response.idToken;
}

export async function getAccessToken() {
    const account = msalInstance.getActiveAccount();    //Grab the logged in user
    if (!account) { //Throws error if no user is logged in and set as the active account.
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,    //{scopes: ["User.Read"]]}
        account: account
    });

    return response.accessToken;
}
