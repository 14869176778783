import {getIDToken} from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class TimeTaskCodeApi {
    /**
     * method getAllTimeTaskCodes
     * @returns a promise of an array of all Time Task Codes.
     */
    async getAllTimeTaskCodes() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/task-code`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method getActiveTimeTaskCodes
     * @returns a promise of an array of all active Time Task Codes.
     */
    async getActiveTimeTaskCodes() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/task-code/active`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method getInactiveTimeTaskCodes
     * @returns a promise of an array of all inactive Time Task Codes.
     */
    async getInactiveTimeTaskCodes() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/task-code/inactive`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method addTimeTaskCode
     * @param {Object} taskCodeObj- TimeTaskCodeDepartmentNM (string): The name the department the Task Code represents,
     * TimeTaskCodeNM (string): A three digit string that is used for payroll purposes. 
     * @returns the newly created TimeTaskCode.
     */
    async addTimeTaskCode(taskCodeObj) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/task-code`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(taskCodeObj)
        }).then(response => response.json());
    }

    /**
     * method editTimeTaskCode
     * @param {Object} taskCodeObj- TimeTaskCodeID (number): The primary key of the Time Task Code to edit,
     * TimeTaskCodeDepartmentNM (string): The name the department the Task Code represents,
     * TimeTaskCodeNM (string): A three digit string that is used for payroll purposes. 
     * @returns the newly created TimeTaskCode.
     */
    async editTimeTaskCode(taskCodeObj) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/task-code`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(taskCodeObj)
        }).then(response => response.json());
    }

    /**
     * method toggleActiveStatus
     * @param {Object} taskCodeObj- TimeTaskCodeID (number): The primary key of the Time Task Code to edit,
     * TimeTaskCodeActiveFLG (number): a 1 or 0 to activate (1) or deactivate (0) the value
     * @returns 
     */
    async toggleActiveStatus(taskCodeObj) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/task-code/active-status`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(taskCodeObj)
        }).then(response => response.json());
    }
}