import {getAccessToken} from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class DashboardAPI {

    async getEmbedReport () {
        const idToken = await getAccessToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/dashboard/embed`, {
            headers: headers
        }).then(response => response.json());
    }
}