import React, {useState} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

export default function DeleteButton({id, handleDelete, disabled}) {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const deleteItem = () => {
        setModal(false);
        handleDelete(id);
    }
    return (
        <div>
            <button className="btn btn-danger" onClick={toggle} disabled={disabled}>Delete</button>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader>Delete Item</ModalHeader>
                <ModalBody>Are you sure you want to delete this item?</ModalBody>
                <ModalFooter>
                    <button className="btn btn-danger" onClick={deleteItem}>Delete</button>
                    <button className="btn btn-secondary" onClick={toggle}>Close</button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
