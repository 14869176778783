import React, { useState } from 'react';
import { DeleteButton, EditButton, ObjectDropdown, ObjectMultiSelect, TimePicker } from '..';
import { hourDifference, timeToMilitaryTime } from '../../../utils/date-format';
import { userPayCode } from '../../../utils/string-functions';
import { pickerDateToTable, pickerTimeToTable } from '../../../utils/date-format';
import { arrayToStringListNoAnd } from '../../../utils/string-functions';

export default function TableRow({ columns, rowData, isAdd }) {
    const [fields, setFields] = useState(rowData);
    const [editable, setEditable] = useState(isAdd);

    const handleDTSCompare = (date1, hour1, minute1, period1, date2, hour2, minute2, period2) => {
        if(date1 !== "" && hour1 !== "" && minute1 !== "" && period1 !== "" 
            && date2 !== "" && hour2 !== "" && minute2 !== "" && period2 !== "") {
            const time1 = timeToMilitaryTime(hour1, minute1, period1);
            const time2 = timeToMilitaryTime(hour2, minute2, period2);
            return hourDifference(date1, time1, date2, time2);
        } else {
            return 0;
        }
    }

    //handles the state of the row's fields.
    const handleFieldEdit = (value, dataKey) => {
        const fieldsCopy = {...fields};
        fieldsCopy[dataKey] = value;
        setFields(fieldsCopy);
    }

    //Handles the state of the row's fields when one column has the ability to edit another
    const editMultiple = (value, dataKey1, dataKey2) => {
        const fieldsCopy = {...fields};
        fieldsCopy[dataKey1] = value;
        fieldsCopy[dataKey2] = value;
        setFields(fieldsCopy);
    }

    const objDropdownImpactsOtherField = (value, dataKey1, dataKey2, subDataKey) => {
        const fieldsCopy = {...fields};
        fieldsCopy[dataKey1] = value;
        fieldsCopy[dataKey2] = value[subDataKey];
        setFields(fieldsCopy);
    }

    //Maps out each column of the row, looking at the columns setup to know data type or item to put in each td.
    const displayTableData = columns.map((column, index) => {
        if(column.type === "string") {
            //If both the column and the row are editable, display an input. Otherwise, display the text.
            if(editable && column.editable) {
                return (
                    <td key={column.dataKey}>
                        <input
                            className="form-control"
                            type="text" 
                            value={fields[column.dataKey]} 
                            onChange={({target}) => handleFieldEdit(target.value, column.dataKey)}
                        />
                    </td>
                )
            } else {
                return (
                    <td key={column.dataKey}>
                        {fields[column.dataKey] !== null ? fields[column.dataKey] : "None"}
                    </td>
                )
            }
        } else if(column.type === "number") {
            //If both the column and the row are editable, display an input. Otherwise, display the text.
            if(editable && column.editable) {
                return (
                    <td key={column.dataKey}>
                        <input
                            className="form-control"
                            type="number" 
                            value={fields[column.dataKey]} 
                            onChange={({target}) => handleFieldEdit(target.value, column.dataKey)}
                        />
                    </td>
                )
            } else {
                return (
                    <td key={column.dataKey}>
                        {fields[column.dataKey] !== null ? fields[column.dataKey] : "None"}
                    </td>
                )
            }
        } else if(column.type === "object-dropdown") {
            //If both column and row are editable, display a dropdown. Otherwise display the selected option as text.
            if(column.editable && editable) {
                if(column.alsoEdit) {
                    return (
                        <td key={column.dataKey}>
                            <ObjectDropdown 
                                items={column.options}
                                itemNameKey={column.itemNameKey}
                                itemNameSecondKey={column.itemNameSecondKey ? column.itemNameSecondKey : null}
                                label={column.label}
                                dataKey={column.dataKey}
                                dataKey2={column.alsoEdit}
                                subDataKey={column.withValue}
                                selected={fields[column.dataKey]}
                                setSelectedWithDataKeyImpactsOtherField={objDropdownImpactsOtherField}
                                isBig={false}
                            />
                        </td>
                    )
                }
                return (
                    <td key={column.dataKey}>
                        <ObjectDropdown 
                            items={column.options}
                            itemNameKey={column.itemNameKey}
                            itemNameSecondKey={column.itemNameSecondKey ? column.itemNameSecondKey : null}
                            label={column.label}
                            dataKey={column.dataKey}
                            selected={fields[column.dataKey]}
                            setSelectedWithDataKey={handleFieldEdit}
                            isBig={false}
                            searchable={true}
                        />
                    </td>
                )
            } else {
                let displaySelected = fields[column.dataKey] !== null && fields[column.dataKey][column.itemNameSecondKey]
                    ? fields[column.dataKey][column.itemNameKey].concat(" ", fields[column.dataKey][column.itemNameSecondKey]) 
                    : fields[column.dataKey] !== null ? fields[column.dataKey][column.itemNameKey]
                    : ""
                return (
                    <td key={column.dataKey}>
                        {displaySelected}
                    </td>
                )
            }
        } else if(column.type === "object-multi-select") {
            if(column.editable && editable) {
                return (
                    <td key={column.dataKey}>
                        <ObjectMultiSelect 
                            items={column.options}
                            itemNameKey={column.itemNameKey}
                            itemNameSecondKey={column.itemNameSecondKey ? column.itemNameSecondKey : null}
                            itemPrimaryKey={column.itemPrimaryKey}
                            label={column.label}
                            dataKey={column.dataKey}
                            selected={fields[column.dataKey]}
                            setSelectedWithDataKey={handleFieldEdit}
                            isBig={false}
                            searchable={true}
                        />
                    </td>
                )
            } else {
                return (
                    <td key={column.dataKey}>
                        {fields[column.dataKey] !== null ? ` ${fields[column.dataKey].length} selected` : "0 selected"}
                    </td>
                )
            }
        } else if(column.type === "edit-button") {
            return (
                <td key={column.dataKey}>
                    <EditButton 
                        id={rowData[column.dataKey]}
                        handleEdit={column.function}
                        editable={editable}
                        setEditable={setEditable}
                        disabled={column.disabled ? column.disabled : false}
                        data={fields}
                    />
                </td>
            )
        } else if(column.type === "view-switch-button") {
            return (
                <td key={column.dataKey}>
                    <button className="btn btn-primary" onClick={() => column.function(fields)}>
                        {column.text}
                    </button>
                </td>
            )
        } else if(column.type === "add-button") {
            return (
                <td key={column.dataKey}>
                    <button className="btn btn-primary" onClick={() => column.function(fields, setFields)} disabled={column.disableAddButton}>
                        Submit
                    </button>
                </td>
            )
        //If the column type is boolean, display a checkbox.
        } else if(column.type === "boolean") {
            //Unless both the column and row are editable, disable the checkbox to make it view only.
            const isDisabled = editable && column.editable ? false : true;
            return (
                <td key={column.dataKey}>
                    <input
                        className="form-check-input"
                        type="checkbox" 
                        checked={ fields[column.dataKey]} 
                        disabled={isDisabled} 
                        onClick={() => handleFieldEdit(!fields[column.dataKey], column.dataKey)}
                    />
                </td>
            )
        } else if(column.type === "date") {
            if(editable && column.editable) {
                //If editing this columm should impact another column with the same value
                if(column.alsoEdit) {
                    return (
                        <td key={column.dataKey}>
                            <input
                                className="form-control"
                                type="date" 
                                value={fields[column.dataKey]} 
                                onChange={({target}) => editMultiple(target.value, column.dataKey, column.alsoEdit)}
                            />
                        </td>
                    )
                } else {
                    return (
                        <td key={column.dataKey}>
                            <input
                                className="form-control"
                                type="date" 
                                value={fields[column.dataKey]} 
                                onChange={({target}) => handleFieldEdit(target.value, column.dataKey)}
                            />
                        </td>
                    )
                }
            } else {
                return (
                    <td key={column.dataKey}>
                        {pickerDateToTable(fields[column.dataKey])}
                    </td>
                )
            }
        } else if(column.type === "time") {
            if(editable && column.editable) {
                return (
                    <td key={column.dataKey}>
                        <TimePicker
                            value={fields[column.dataKey]}
                            fields={fields}
                            setFields={setFields}
                            dataKey={column.dataKey}
                            hourKey={column.hourKey}
                            minuteKey={column.minuteKey}
                            periodKey={column.periodKey}
                            handleFieldEdit={handleFieldEdit}
                        />
                    </td>
                )
            } else {
                return (
                    <td key={column.dataKey}>
                        {pickerTimeToTable(fields[column.dataKey])}
                    </td>
                )
            }
        } else if(column.type === "date-time-compare") {
            const hoursToDisplay = handleDTSCompare(fields[column.dateCompare1], fields[column.hourCompare1], fields[column.minuteCompare1], 
                fields[column.periodCompare1], fields[column.dateCompare2], fields[column.hourCompare2], fields[column.minuteCompare2], 
                fields[column.periodCompare2]);
            return (
                <td key={column.dataKey}>
                    {hoursToDisplay}
                </td>
            )
        } else if(column.type === "name-code") {
            if(editable && column.editable) {
                return (
                    <td key={column.dataKey}>
                        <input
                            className="form-control"
                            type="text" 
                            value={fields[column.dataKey]} 
                            onChange={({target}) => handleFieldEdit(target.value, column.dataKey)}
                        />
                    </td>
                )
            } else {
                if(fields[column.dataKey] === null) {
                    handleFieldEdit(userPayCode(fields[column.firstName], fields[column.lastName]), column.dataKey);
                }
                return (
                    <td key={column.dataKey}>
                        {fields[column.dataKey] !== null ? fields[column.dataKey] : "None"}
                    </td>
                )
            }
        } else if(column.type === "delete-button") {
            return (
                <td key={column.dataKey}>
                    <DeleteButton
                        id={rowData[column.primaryKey]}
                        handleDelete={column.function}
                        disabled={!editable}
                    />
                </td>
            )
        } else if(column.type === "string-array") {
            return (
                <td key={column.dataKey}>
                    {arrayToStringListNoAnd(rowData[column.dataKey])}
                </td>
            )
        }else {
            return null;
        }
    })
    return (
        <tr>
            {displayTableData}
        </tr>
    )
}
