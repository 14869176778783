import React, { useEffect, useState } from 'react';
import AddTime from '../log-time/add-time';
import TimePunchTable from '../log-time/time-punch-table';
import { OvertimeApi, PayPeriodApi, PersonApi, TimeActivityCodeApi, TimePunchApi } from '../../shared/services';
import { ObjectDropdown } from '../../shared/components';
import { useAuth } from "../../context/auth-context";

export default function AdminEditTime() {
    const [timeActivityCodes, setTimeActivityCodes] = useState([]);
    const { currentUser, isAdmin } = useAuth();
    const [supervisors, setSupervisors] = useState([]);
    const [supervisor, setSupervisor] = useState(currentUser);
    const [employee, setEmployee] = useState(currentUser);
    const [employees, setEmployees] = useState([]);
    const [payPeriods, setPayPeriods] = useState([]);
    const [payPeriod, setPayPeriod] = useState(null);
    const [timePunches, setTimePunches] = useState([]);
    const [payPeriodReport, setPayPeriodReport] = useState(null);
    const [showDateRange, setShowDateRange] = useState(false);
    const [dateRangeForm, setDateRangeForm] = useState({ start: "", end: "" });
    const [displayedTotalHours, setDisplayedTotalHours] = useState(0);
    const myTimePunchApi = new TimePunchApi();

    useEffect(() => {
        const myPersonApi = new PersonApi();
        myPersonApi.getSupervisors()
            .then(supervisorList => {
                setSupervisors(supervisorList);
            });
        const myPayPeriodApi = new PayPeriodApi();
        myPayPeriodApi.getPayPeriods()
            .then(payPeriodList => {
                setPayPeriods(payPeriodList);
            });
    }, []);

    useEffect(() => {
        const myPersonApi = new PersonApi();
        if (supervisor) {
            myPersonApi.getSupervisedEmployees(supervisor.PersonID)
                .then(employeeList => {
                    setEmployees(employeeList);
                    const firstEmployee = employeeList[0] ? employeeList[0] : null;
                    setEmployee(firstEmployee);
                });
        } else {
            myPersonApi.getAllSupervisedEmployees()
                .then(employeeList => {
                    setEmployees(employeeList);
                    const firstEmployee = employeeList[0] ? employeeList[0] : null;
                    setEmployee(firstEmployee);
                })
        }
    }, [supervisor]);

    useEffect(() => {
        if (employee) {
            const myTimeActivityCodeApi = new TimeActivityCodeApi();
            myTimeActivityCodeApi.getEmployeeTimeActivityCodes(employee.PersonID)
                .then(activityCodes => {
                    setTimeActivityCodes(activityCodes);
                });
        }
    }, [employee]);

    useEffect(() => {
        if (employee && payPeriod) {
            const myOvertimeApi = new OvertimeApi()
            if (payPeriod) {
                myOvertimeApi.getEmployeePayPeriodOvertime(employee.PersonID, payPeriod.PayPeriodID)
                    .then(payPeriodOvertime => {
                        setPayPeriodReport(payPeriodOvertime);
                    });
            } else if (payPeriod === "date range") {
                setShowDateRange(true);
            }
        }
    }, [employee, payPeriod, timePunches]);

    // Calculate total hours to display
    useEffect(() => {
        // Get time punches hours
        const getTimePunchHours = () => timePunches.map(tp => tp.hours).reduce((a, b) => a + b, 0)

        // If custom date range is selected
        if (showDateRange) {
            setDisplayedTotalHours(<div className="col-2">
                Custom Range Hours: {getTimePunchHours()}
                </div>);
        // If a payperiod is selected
        } else if (payPeriodReport && payPeriod) {
            setDisplayedTotalHours(<div className="col-2">
                Pay Period Hours: {payPeriodReport.TotalHours ? payPeriodReport.TotalHours : 0}
            </div>);
        // If all is selected
        } else {
            setDisplayedTotalHours(<div className="col-2">
                Total Hours: {getTimePunchHours()}
                </div>);
        }
    }, [showDateRange, timePunches, payPeriodReport, payPeriod]);

    const approveMultiple = () => {
        myTimePunchApi.approveEmployeeTimePunchesByPeriod({
            PayPeriodStartDTS: payPeriod.PayPeriodStartDTS,
            PayPeriodEndDTS: payPeriod.PayPeriodEndDTS,
            TimePunchApprovedFLG: 1, //True
            PersonID: employee.PersonID
        }).then(updatedPunchesList => {
            //Todo: handle any error that occurs
            const timePunchesCopy = [...timePunches];
            timePunchesCopy.forEach((timePunch) => {
                timePunch.TimePunchApprovedFLG = true;
            });
            setTimePunches(timePunchesCopy);
        })
        .catch(function(error) {
            console.log(error)
        })
    }

    const supervisorDropdown = isAdmin() ?
        <ObjectDropdown
            items={supervisors}
            itemNameKey="FirstNM"
            itemNameSecondKey="LastNM"
            selected={supervisor}
            setSelected={setSupervisor}
            label="Supervisor"
            isBig={true}
            selectAll={true}
            searchable={true}
        />
        : currentUser.FirstNM + " " + currentUser.LastNM;

    const dateRange = showDateRange ?
        <div>
            <div className="input-group my-1">
                <span className="input-group-text" id="basic-addon3">Start</span>
                <input type="date" className="form-control" aria-describedby="basic-addon3" value={dateRangeForm.start} onChange={(e) => setDateRangeForm(prev => ({ ...prev, start: e.target.value }))} />
            </div>
            <div className="input-group my-1">
                <span className="input-group-text" id="basic-addon3">End</span>
                <input type="date" className="form-control" aria-describedby="basic-addon3" value={dateRangeForm.end} onChange={(e) => setDateRangeForm(prev => ({ ...prev, end: e.target.value }))} />
            </div>
        </div> : ""

    return (
        <div className="mx-4 mt-2">
            <div className="row">
                <div className="col col-lg-2">
                    <label>Supervisor/Admin</label>
                </div>
                <div className="col col-lg-2">
                    <label>Employee</label>
                </div>
                <div className="col col-lg-3">
                    <label>Payperiod / Date Range</label>
                </div>
            </div>
            <div className="row">
                <div className="col col-lg-2">
                    {supervisorDropdown}
                </div>
                <div className="col col-lg-2">
                    <ObjectDropdown
                        items={employees}
                        itemNameKey="FirstNM"
                        itemNameSecondKey="LastNM"
                        selected={employee}
                        setSelected={setEmployee}
                        label="Employee"
                        isBig={true}
                        searchable={true}
                    />
                </div>
                <div className="col col-lg-3">
                    <ObjectDropdown
                        items={payPeriods}
                        itemNameKey="PayPeriodStartDTSPretty"
                        itemNameDivider=" - "
                        itemNameSecondKey="PayPeriodEndDTSPretty"
                        selected={payPeriod}
                        setSelected={setPayPeriod}
                        label="Pay Period"
                        isBig={true}
                        selectAll={true}
                        searchable={true}
                        dateRange={true}
                        setDateRangeSelected={setShowDateRange}
                    />
                    {dateRange}
                </div>
                <div className="col col-lg-2">
                    <button
                        className="btn btn-primary"
                        disabled={employee === null || payPeriod === null}
                        onClick={approveMultiple}
                    >
                        Approve Multiple
                    </button>
                </div>

                {/* Show total hours for the timepunches displayed */}
                {displayedTotalHours}
            </div>
            <h5 className="title">Add Time {employee ? "For " + employee.FirstNM + " " + employee.LastNM : ""}</h5>
            <AddTime
                timeActivityCodes={timeActivityCodes}
                employee={employee}
                payPeriod={payPeriod}
                timePunches={timePunches}
                setTimePunches={setTimePunches}
            />
            <h5 className="title">Edit Time</h5>
            <TimePunchTable
                timeActivityCodes={timeActivityCodes}
                employee={employee}
                payPeriod={payPeriod}
                canApprove={true}
                timePunches={timePunches}
                setTimePunches={setTimePunches}
                allowDelete={true}
                dateRange={showDateRange && dateRangeForm.start !== "" && dateRangeForm.end !== "" ? dateRangeForm : false}
            />
        </div>
    )
}
