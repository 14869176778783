import React, {useEffect, useState} from 'react';
import { ObjectDropdown, Table } from '../../shared/components';
import { OvertimeApi, PayPeriodApi } from '../../shared/services';
import OvertimeEditTime from './overtime-edit-time';
import { useAuth } from '../../context/auth-context';

export default function ViewOvertime() {
    const [overtime, setOvertime] = useState([]);
    const [payPeriods, setPayPeriods] = useState([]);
    const [payPeriod, setPayPeriod] = useState(null);
    const [view, setView] = useState("overtime-table");
    const [overtimeWeekToEdit, setOvertimeWeekToEdit] = useState(null);
    const [timePunches, setTimePunches] = useState([]);
    const { currentUser, isSupervisor} = useAuth();

    useEffect(() => {
        const myPayPeriodApi = new PayPeriodApi();
        myPayPeriodApi.getPayPeriods()
            .then(periodList => {
                setPayPeriods(periodList);
            });
    }, [])
    
    useEffect(() => {
        if(payPeriod) {
            const myOvertimeApi = new OvertimeApi();
            if(isSupervisor()) {
                myOvertimeApi.getPayPeriodOvertimeForSupervisor(payPeriod.PayPeriodID, currentUser.PersonID)
                    .then(overtimeList => {
                        setOvertime(overtimeList);
                    })
            } else {
                myOvertimeApi.getPayPeriodOvertime(payPeriod.PayPeriodID)
                    .then(overtimeList => {
                        setOvertime(overtimeList);
                    });
            }
        }
    }, [currentUser, payPeriod, isSupervisor, timePunches]);

    const viewTimeCards = (data) => {
        setView("edit-time-card");
        setOvertimeWeekToEdit(data);
    }

    const columns = [
        {
            header: "PAYROLL CODE NAME",
            dataKey: "EmployeePayrollCodeNM",
            editable: false,
            type: "string"
        },
        {
            header: "TOTAL HOURS",
            dataKey: "TotalHours",
            editable: false,
            type: "number"
        },
        {
            header: "OVERTIME HOURS",
            dataKey: "OverTimeHours",
            editable: false,
            type: "number"
        },
        {
            header: "WEEK START",
            dataKey: "WeekStartDTS",
            editable: false,
            type: "date"
        },
        {
            header: "WEEK END",
            dataKey: "WeekEndDTS",
            editable: false,
            type: "date"
        },
        {
            header: "VIEW/EDIT TIME CARD",
            dataKey: "view-time-card-button",
            editable: false,
            type: "view-switch-button",
            text: "Edit Time Card",
            function: viewTimeCards
        }
    ];

    if(view === "overtime-table") {
        return (
            <>
                <h5 className="title">Overtime</h5>
                <ObjectDropdown 
                    items={payPeriods}
                    itemNameKey="PayPeriodStartDTSPretty"
                    itemNameDivider=" - "
                    itemNameSecondKey="PayPeriodEndDTSPretty"
                    selected={payPeriod}
                    setSelected={setPayPeriod}
                    label="Pay Period"
                    isBig={true}
                    searchable={true}
                />
                <Table
                    columns={columns}
                    rowsData={overtime}
                    primaryKey="PersonID"
                    secondKey="WeekStartDTS"
                    scrollable={true}
                    height="500"
                />
            </>
        )
    } else {
        return (
            <>
                <OvertimeEditTime
                    overtimeWeekToEdit={overtimeWeekToEdit}
                    setView={setView}
                    timePunches={timePunches}
                    setTimePunches={setTimePunches}
                />
            </>
        )
    }
}
