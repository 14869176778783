import {getIDToken} from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class TimePunchApi {

    /**
     * method getEmployeeTimePunches
     * @param {number} PersonID: the employee's person ID
     * @returns a promised array of all time punches that match the employee
     */
    async getEmployeeTimePunches(PersonID) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/time-punch/person?&PersonID=${PersonID}`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method getEmployeeDRTimePunches
     * @param {number} PersonID: the employee's person ID
     * @param {string} start Date: the start date
     * @param {string} end Date: the end date
     * @returns a promised array of all time punches that match the employee
     */
     async getEmployeeDRTimePunches(PersonID, start, end) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/time-punch/person?PersonID=${PersonID}&start=${start}&end=${end}`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method getEmployeeTimePunchesByPeriod
     * @param {number} PayPeriodID: the ID of the pay period we want the time punches of
     * @param {number} PersonID: the employee's person ID
     * @returns a promised array of all time punches that match the pay period and employee
     */
    async getEmployeeTimePunchesByPeriod(PayPeriodID, PersonID) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/time-punch/period/person?PayPeriodID=${PayPeriodID}&PersonID=${PersonID || ""}`, {
            headers: headers
        }).then(response => response.json());
    }

    async getUnapprovedTimePunchesByPayPeriodWithSupervisor(PayPeriodID) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/time-punch/unapproved/period?PayPeriodID=${PayPeriodID}`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method addTimePunch
     * @param {object} timePunchObj- PersonID: the employee the time punch is connected to (could be the current user or an admin adding a punch for their employee),
     * TimeInDTS: a string describing the date time stamp the employee started working
     * TimeOutDTS: a string describing the date time stamp the employee stopped working
     * TimeActivityCodeID: the ID of the Activity Code the employee is logging hours toward.
     * TimePunchApprovedFLG: 1 to approve, 0 to disapprove.
     * @returns the newly created Time Punch
     */
    async addTimePunch(timePunchObj) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/time-punch`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(timePunchObj)
        }).then(response => response.json());
    }

    /**
     * method editTimePunch
     * @param {object} timePunchObj - TimePunchID: the ID of the time punch to update.
     * PersonID: the employee the time punch is connected to (could be the current user or an admin adding a punch for their employee),
     * TimeInDTS: a string describing the date time stamp the employee started working
     * TimeOutDTS: a string describing the date time stamp the employee stopped working
     * TimeActivityCodeID: the ID of the Activity Code the employee is logging hours toward.
     * TimePunchApprovedFLG: 1 to approve, 0 to disapprove. 
     * @returns the newly edited time punch
     */
    async editTimePunch(timePunchObj) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/time-punch/edit`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(timePunchObj)
        }).then(response => response.json());
    }

    /**
     * method approveEmployeeTimePunchesByPeriod
     * @param {object} timePunchObj - PayPeriodStartDTS: the start date of the pay period.
     * PayPeriodEndDTS: the end date of the pay period.
     * PersonID: the employee's PersonID to approve time punches of.
     * TimePunchApprovedFLG: 1 to approve all within the requested range, 0 to unapprove.
     * @returns an array of all time punches that were edited in the database.
     */
    async approveEmployeeTimePunchesByPeriod(timePunchObj) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/time-punch/approve/employee/range`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(timePunchObj)
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }

            return response.json()
        });
    }

    async deleteTimePunch(TimePunchID) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/time-punch/${TimePunchID}`, {
            method: "DELETE",
            headers: headers
        }).then(response => response.json());
    }
}