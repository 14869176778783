import React, {useEffect, useState} from 'react';
import TimePunchTable from '../log-time/time-punch-table';
import { TimeActivityCodeApi } from '../../shared/services';
import { backIcon } from '../../assets/images/images';

export default function OvertimeEditTime({overtimeWeekToEdit, setView, timePunches, setTimePunches}) {
    const [timeActivityCodes, setTimeActivityCodes] = useState([]);


    useEffect(() => {
        if(overtimeWeekToEdit) {
            const myTimeActivityCodeApi = new TimeActivityCodeApi();
            myTimeActivityCodeApi.getEmployeeTimeActivityCodes(overtimeWeekToEdit.PersonID)
                .then(activityCodes => {
                    setTimeActivityCodes(activityCodes);
                });
        }
    }, [overtimeWeekToEdit]);

    return (
        <>
            <button className="btn" onClick={() => setView("overtime-table")}>
                <img src={backIcon} className="edit" alt="back"/>
            </button>
            <h5 className="title">Edit Overtime Punches</h5>
            <TimePunchTable
                timeActivityCodes={timeActivityCodes}
                employeeID={overtimeWeekToEdit.PersonID}
                weekStart={overtimeWeekToEdit.WeekStartDTS}
                timePunches={timePunches}
                setTimePunches={setTimePunches}
            />
        </>
    )
}
