import React from 'react';
import { useIsAuthenticated } from "@azure/msal-react";
import {LoginButton, LogoutButton} from "./login-button";

export default function LoginAndLogOutButton() {
    const isAuthenticated = useIsAuthenticated();

    if(isAuthenticated) {
        return ( <LogoutButton/> )
    } else {
        return ( <LoginButton/> )
    }

}
