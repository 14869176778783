import 'bootstrap/dist/css/bootstrap.min.css';    //Adds Bootstrap styling to entire project
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, useHistory} from "react-router-dom";

import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { AuthProvider } from './client/context/auth-context';
import { loginRequest } from './client/config/auth-config';
import { msalInstance } from './client/utils/msal-instance';
import { CustomNavigationClient } from './client/utils/navigation-client';

import Transitions from "./client/transitions/transitions";

function App({ pca }) {
    //Set up a history for redirecting and allow msalInstance to access it.
    const history = useHistory();
    const navigationClient = new CustomNavigationClient(history);
    pca.setNavigationClient(navigationClient);

    const authRequest = {
        ...loginRequest
    }

    return (
        <div>
            <MsalProvider instance={pca}>
                <AuthProvider>
                    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
                        <Transitions/>
                    </MsalAuthenticationTemplate>
                </AuthProvider>
            </MsalProvider>
        </div>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <App pca={msalInstance}/>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);