import React from 'react';
import { Link } from 'react-router-dom';
import "./navbar.components.css";
import { scenicviewBannerLogo } from '../../../assets/images/images';
import LoginAndLogOutButton from "../login-and-logout/login-and-logout";
import { useAuth } from '../../../context/auth-context';

const navbarStyle = {
    backgroundColor: '#1B4B6D', // Set the background color to #1B4B6D
};

const logoStyle = {
    maxHeight: '50px', // Adjust the height as needed
    maxWidth: '150px', // Adjust the width as needed
};

export default function Navbar() {
    const {isAdmin, isSupervisor, isPowerBIAdmin} = useAuth();

    const adminSupervisorLink = isAdmin() ? 
    <li class="nav-item"><Link to ="/admin" className="nav-link nav-link-txt navhover" href="/">ADMIN</Link></li>
        : isSupervisor() ? 
        <li class="nav-item"><Link to ="/supervisor" className="nav-link nav-link-txt navhover" href="/">SUPERVISOR</Link></li>
        : null;

    return (
            <nav class="navbar navbar-expand-lg navbar-light" style={navbarStyle}>
                <div class="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src={scenicviewBannerLogo} alt="ScenicView Academy" style={logoStyle}/>
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse justify-content-end" id="navbarTogglerDemo02">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <Link to ="/" className="nav-link nav-link-txt navhover" href="/">HOME</Link>
                            </li>
                            {isPowerBIAdmin() ? (
                            <li class="nav-item">
                                <Link to="/dashboard" className="nav-link nav-link-txt navhover" href="">DASHBOARD</Link>
                            </li>
                            ) : ""}
                            {adminSupervisorLink}
                            <li class="nav-item">
                                <LoginAndLogOutButton/>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
    )
}


