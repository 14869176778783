import React, {useState} from 'react';
import AdminEditTime from '../admin-edit-time/admin-edit-time';
import ViewOvertime from '../overtime/view-overtime';

export default function Supervisor() {
    const [currentView, setCurrentView] = useState("edit-time");
    
    const pageToDisplay = currentView === "edit-time" ? <AdminEditTime/>
        : <ViewOvertime/>

    const activeTab = "nav-link adm-link active";
    const passiveTab = "nav-link adm-link";
    return (
        <div className="container-fluid">
            <ul className="nav nav-tabs mt-2 mb-2" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className={currentView === "edit-time" ? activeTab : passiveTab} onClick={() => setCurrentView("edit-time")}>
                        EDIT AND APPROVE TIME
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={currentView === "overtime" ? activeTab : passiveTab} onClick={() => setCurrentView("overtime")}>
                        OVERTIME REVIEW
                    </button>
                </li>
            </ul>
                {pageToDisplay}
        </div>
    )
}
