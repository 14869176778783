import {getIDToken} from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class OvertimeApi {

    async getEmployeeOvertime (PersonID) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/overtime/employee?PersonID=${PersonID}`, {
            headers: headers
        }).then(response => response.json());
    }

    async getPayPeriodOvertime (PayPeriodID) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/overtime/pay-period?PayPeriodID=${PayPeriodID}`, {
            headers: headers
        }).then(response => response.json());
    }

    async getPayPeriodOvertimeForSupervisor (PayPeriodID, SupervisorPersonID) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/overtime/supervisor/pay-period?PayPeriodID=${PayPeriodID}&SupervisorPersonID=${SupervisorPersonID || ""}`, {
            headers: headers
        }).then(response => response.json());
    }

    async getEmployeeOvertimePunches (PersonID, FirstofWeek) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/overtime/punches?PersonID=${PersonID  || ""}&FirstofWeek=${FirstofWeek}`, {
            headers: headers
        }).then(response => response.json());
    }

    async getEmployeeWeekOvertime (PersonID, CurrentDTS) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/overtime/employee/week?PersonID=${PersonID  || ""}&CurrentDTS=${CurrentDTS}`, {
            headers: headers
        }).then(response => response.json())
        .catch(err => console.log(err));
    }
    
    async getEmployeePayPeriodOvertime (PersonID, PayPeriodID) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/overtime/employee/pay-period?PersonID=${PersonID  || ""}&PayPeriodID=${PayPeriodID}`, {
            headers: headers
        }).then(response => response.json());
    }
}