import React, {useEffect, useState} from 'react'
import DashboardAPI from '../../shared/services/dashboard.api';
import EmbeddedBi from './embedded-bi';

export default function Dashboard() {
    const [accessToken, setAccessToken] = useState()
    useEffect(() => {
        const myEmbedDashboardApi = new DashboardAPI();
        myEmbedDashboardApi.getEmbedReport()
            .then(token => {
                setAccessToken(token.access_token);
            })
    }, []);
  return (
    <>
    {accessToken ? (<EmbeddedBi accessToken={accessToken} />) : ""}
        
    </>
  )
}
