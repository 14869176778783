import React, { useEffect, useState } from 'react';
import ActivityCodeTable from './activity-code-table';
import AddActivityCode from './add-activity-code';
import { PTOTypeApi, TimeActivityCodeApi, TimeTaskCodeApi } from '../../shared/services';

export default function ActivityCodes() {
    const [ptoTypes, setPTOTypes] = useState([]);
    const [timeActivityCodes, setTimeActivityCodes] = useState([]);
    const [timeTaskCodes, setTimeTaskCodes] = useState([]);

    useEffect(() => {
        const myTimeActivityCodeApi = new TimeActivityCodeApi();
        myTimeActivityCodeApi.getAllTimeActivityCodes()
            .then(activityCodes => {
                setTimeActivityCodes(activityCodes);
            });
        
        const myTimeTaskCodeApi = new TimeTaskCodeApi();
        myTimeTaskCodeApi.getActiveTimeTaskCodes()
            .then(taskCodes => {
                setTimeTaskCodes(taskCodes.sort((a,b) => a.TimeTaskCodeDepartmentNM > b.TimeTaskCodeDepartmentNM ? 1: -1));
            });

        const myPTOTypeApi = new PTOTypeApi();
        myPTOTypeApi.getPTOTypes()
            .then(types => {
                setPTOTypes(types);
            });
    }, []);

    return (
        <div className="mx-4 mt-2">
            <h5 className="title">Add Activity Code</h5>
            <AddActivityCode
                ptoTypes={ptoTypes}
                timeActivityCodes={timeActivityCodes}
                setTimeActivityCodes={setTimeActivityCodes}
                timeTaskCodes={timeTaskCodes}
            />
            <h5 className="title">Activity Codes</h5>
            <ActivityCodeTable
                ptoTypes={ptoTypes}
                timeActivityCodes={timeActivityCodes} 
                setTimeActivityCodes={setTimeActivityCodes}
                timeTaskCodes={timeTaskCodes}
            />
        </div>
    )
}
