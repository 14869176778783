import React, {useEffect, useState} from 'react';
import TableRow from './table-row';
import "./table.css";

export default function Table({ columns, rowsData, primaryKey, secondKey, isAdd, toggleData, searchableColumns, scrollable, height}) {
    const [tableToggle, setTableToggle] = useState(true);
    const [filteredRowsData, setFilteredRowsData] = useState(rowsData);
    const [searchQuery, setSearchQuery] = useState("");

    //This useEffect filters down the table data based off of what the user inputs in the search bar as well as the state of the custom toggle
    useEffect(() => {
        //If both the search bar and toggle are actively in use
        if(searchQuery !== "" && searchableColumns && tableToggle && toggleData) {
            const filteredDownRows = rowsData.filter(rowData => {
                let keepRow = false;
                for(let searchableColumn of searchableColumns) {
                    let rowAndColumn = rowData[searchableColumn.dataKey];
                    if(searchableColumn.type === "number" && rowAndColumn !== null) {
                        rowAndColumn = rowAndColumn.toString();
                    } else if(searchableColumn.type === "object" && rowAndColumn !== null) {
                        rowAndColumn = rowAndColumn[searchableColumn.subDataKey];
                    }
                    if(rowAndColumn?.toLowerCase().includes(searchQuery.toLowerCase())) {
                        if(rowData[toggleData.dataKey] === toggleData.filterIfEquals) {
                            keepRow = true;
                        }
                    }
                }
                return keepRow;
            });
            setFilteredRowsData(filteredDownRows);
        //If just the search bar is actively used
        } else if(searchQuery !== "" && searchableColumns) {
            const filteredDownRows = rowsData.filter(rowData => {
                let keepRow = false;
                for(let searchableColumn of searchableColumns) {
                    let rowAndColumn = rowData[searchableColumn.dataKey];
                    if(searchableColumn.type === "number" && rowAndColumn !== null) {
                        rowAndColumn = rowAndColumn.toString();
                    } else if(searchableColumn.type === "object" && rowAndColumn !== null) {
                        rowAndColumn = rowAndColumn[searchableColumn.subDataKey];
                    }
                    if(rowAndColumn?.toLowerCase().includes(searchQuery.toLowerCase())) {
                        keepRow = true;
                    }
                }
                return keepRow;
            });
            setFilteredRowsData(filteredDownRows);
        //If just the toggle is actively used, meaning switched to the on position.
        } else if(tableToggle && toggleData) {
            const filteredDownRows = rowsData.filter(rowData => {
                return rowData[toggleData.dataKey] === toggleData.filterIfEquals;
            });
            setFilteredRowsData(filteredDownRows);
        //If neither the toggle or search bar are used.
        } else {
            setFilteredRowsData(rowsData);
        }

    }, [tableToggle, rowsData, toggleData, searchQuery, searchableColumns]);

    const toggleSwitch = toggleData ? 
        <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" checked={tableToggle} onChange={() => setTableToggle(!tableToggle)}/>
            <label className="form-check-label">{toggleData.toggleLabel}</label>
        </div>
        : null;

    const searchBar = searchableColumns ?
        <input className="form-control" type="text" placeholder="Search" value={searchQuery} onChange={({target}) => setSearchQuery(target.value)}/>
        : null;

    const tableStyle = scrollable  && height ? `table-scroll-${height}`: "";

    return (
        <>
            <div className="row">
                <div className="col-2"></div>
                <div className="col">
                    {toggleSwitch}
                </div>
                <div className="col">
                    {searchBar}
                </div>
            </div>
            <div className={tableStyle}>
                <table className="table table-sm table-hover">
                    <thead className="table-light">
                        <tr>
                            {columns.map((column, index) => {
                                return <th key={index} className="table-column-header">{column.header}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredRowsData.map((rowData) => {
                            return <TableRow 
                                        key={secondKey ? rowData[primaryKey]+rowData[secondKey] : rowData[primaryKey]} 
                                        columns={columns} 
                                        rowData={rowData} 
                                        primaryKey={primaryKey} 
                                        isAdd={isAdd}
                                    />
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}