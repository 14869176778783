import React from 'react';
import { Table } from '../../shared/components';
import { TimeActivityCodeApi } from '../../shared/services';

export default function ActivityCodeTable({ ptoTypes, timeActivityCodes, setTimeActivityCodes, timeTaskCodes }) {

    const handleEdit = (data) => {
        //Lift up foreign key values to parent level from their inner objects
        data.TimeTaskCodeID = data.TimeTaskCode?.TimeTaskCodeID;
        data.PTOTypeID = data.PTOType?.PTOTypeID;

        const myTimeActivityCodeApi = new TimeActivityCodeApi();
        myTimeActivityCodeApi.editTimeActivityCode(data)
            .then(editedActivityCode => {
                //Update the state of the Time Activity Codes array. 
                const timeActivityCodesCopy = [...timeActivityCodes];
                timeActivityCodesCopy.forEach((code, index) => {
                    if(code.TimeActivityCodeID === editedActivityCode.TimeActivityCodeID) {
                        timeActivityCodesCopy[index] = data;
                    }
                });
                setTimeActivityCodes(timeActivityCodesCopy);
            });
        
    }

    const columns = [
        {
            header: "CODE", 
            dataKey: "TimeActivityCodeNM", 
            editable: true, 
            type: "string"
        },
        {
            header: "DESCRIPTION", 
            dataKey: "TimeActivityCodeDSC", 
            editable: true, 
            type: "string"
        },
        {
            header: "RATE", 
            dataKey: "TimeActivityRateNBR", 
            editable: true, 
            type: "string"
        },
        {
            header: "TASK CODE", 
            dataKey: "TimeTaskCode", 
            editable: true, 
            type: "object-dropdown", 
            itemNameKey: "TimeTaskCodeDepartmentNM", 
            options: timeTaskCodes,
            label: "Task Code"
        },
        {
            header: "PTO TYPE", 
            dataKey: "PTOType", 
            editable: true, 
            type: "object-dropdown", 
            itemNameKey: "PTOTypeDSC", 
            options: ptoTypes,
            label: "PTO Type"
        },
        {
            header: "ACTIVE", 
            dataKey: "TimeActivityActiveFLG", 
            editable: true, 
            type: "boolean"
        },
        {
            header: "EDIT", 
            dataKey: "TimeActivityCodeID", 
            editable: false, 
            type: "edit-button", 
            function: handleEdit
        }
    ]

    const toggleData = {
        toggleLabel: "Display Active Only",
        dataKey: "TimeActivityActiveFLG",
        filterIfEquals: true
    }

    const searchableColumns = [
        {
            dataKey: "TimeActivityCodeNM",
            type: "string"
        },
        {
            dataKey: "TimeActivityCodeDSC",
            type: "string"
        },
        {
            dataKey: "TimeActivityRateNBR",
            type: "number"
        },
        {
            dataKey: "TimeTaskCode",
            subDataKey: "TimeTaskCodeDepartmentNM",
            type: "object"
        },
        {
            dataKey: "PTOType",
            subDataKey: "PTOTypeDSC",
            type: "object"
        }
    ]
    return (
        <>
            <Table
                columns={columns}
                rowsData={timeActivityCodes}
                setRowsData={setTimeActivityCodes}
                primaryKey="TimeActivityCodeID"
                isAdd={false}
                toggleData={toggleData}
                searchableColumns={searchableColumns}
                scrollable={true}
                height="500"
            />
        </>
    )
}
