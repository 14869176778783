import React, { useEffect, useState } from 'react';
import { Table } from '../../shared/components';
import { TimePunchApi } from '../../shared/services';
import EditUnapprovedPunches from './edit-unapproved-punches';

export default function UnapprovedPunchSupervisors({payPeriod}) {
    const [supervisorTimePunches, setSupervisorTimePunches] = useState([]);
    const [view, setView] = useState("supervisors");
    const [unapprovedPunches, setUnapprovedPunches] = useState(null);
    const [timePunches, setTimePunches] = useState([]);

    useEffect(() => {
        if(payPeriod) {
            const myTimePunchApi = new TimePunchApi();
            myTimePunchApi.getUnapprovedTimePunchesByPayPeriodWithSupervisor(payPeriod.PayPeriodID)
                .then(supervisorTimePuchesList => {
                    setSupervisorTimePunches(supervisorTimePuchesList);
                });
        }
    }, [payPeriod, timePunches]);

    const viewUnapprovedPunches = (data) => {
        setView("unapproved-punches");
        setUnapprovedPunches(data.Punches);
    }

    const columns = [
        {
            header: "SUPERVISOR FIRST NAME",
            dataKey: "FirstNM",
            editable: false,
            type: "string"
        },
        {
            header: "LAST NAME",
            dataKey: "LastNM",
            editable: false,
            type: "string"
        },
        {
            header: "PUNCH COUNT",
            dataKey: "PunchCount",
            editable: false,
            type: "number"
        },
        {
            header: "EMPLOYEES",
            dataKey: "Employees",
            editable: false,
            type: "string-array"
        },
        {
            header: "VIEW/APPROVE TIME CARDS",
            dataKey: "view-time-card-button",
            editable: false,
            type: "view-switch-button",
            text: "Edit Time Card",
            function: viewUnapprovedPunches
        }
    ]

    if(view === 'supervisors') {
        return (
            <>
                <h5 className="title">Supervisors with Unapproved Punches</h5>
                <Table
                    columns={columns}
                    rowsData={supervisorTimePunches}
                    primaryKey="PersonID"
                    secondKey="PayPeriodID"
                    scrollable={true}
                    height="500"
                />
            </>
        )
    } else {
        return <EditUnapprovedPunches 
            setView={setView}
            unapprovedPunches={unapprovedPunches}
            timePunches={timePunches}
            setTimePunches={setTimePunches}
        />
    }
}
