import React from 'react';
import TimePunchTable from '../log-time/time-punch-table';
import { backIcon } from '../../assets/images/images';

export default function EditUnapprovedPunches({setView, unapprovedPunches, timePunches, setTimePunches}) {
    return (
        <>
           <button className="btn" onClick={() => setView("supervisors")}>
                <img src={backIcon} className="edit" alt="back"/>
            </button>
            <h5 className="title">Edit Unapproved Punches</h5>
            <TimePunchTable 
                unapprovedPunches={unapprovedPunches}
                canApprove={true}
                timePunches={timePunches}
                setTimePunches={setTimePunches}
            />
        </>
    )
}
