import {getIDToken} from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class TimeActivityCodeApi {
    /**
     * method getAllTimeActivityCodes
     * @returns a promised array of all the Time Activity Codes
     */
    async getAllTimeActivityCodes() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/activity-code`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method getActiveTimeActivityCodes
     * @returns a promised array of all active Time Activity Codes
     */
    async getActiveTimeActivityCodes() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/activity-code/active`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method getInactiveTimeActivityCodes
     * @returns a promised array of all inactive Time Activity Codes
     */
    async getInactiveTimeActivityCodes() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/activity-code/inactive`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method getEmployeeTimeActivityCodes
     * @param {integer} PersonID: the ID of the person to grab the Time Activity Codes for 
     * @returns a promised array of Time Activity Codes
     */
    async getEmployeeTimeActivityCodes(PersonID) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/activity-code/employee?PersonID=${PersonID || ""}`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method addTimeActivityCode
     * @param {object} timeActivityCodeObj- TimeActivityCodeDSC (string): describes what the time activity code covers,
     * TimeActivityRateNBR (number, nullable): the pay rate tied to this code,
     * TimeTaskCodeID (number, foreign key): the ID of the Task Code this Activity Code is tied to, 
     * PTOTypeID (number, foreign key): the ID of the PTO category this activity code falls under.
     * @returns the newly created time activity code.
     */
    async addTimeActivityCode(timeActivityCodeObj) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/activity-code`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(timeActivityCodeObj)
        }).then(response => response.json());
    }

    /**
     * method editTimeActivityCode
     * @param {object} timeActivityCodeObj- TimeActivityCodeID (number, primary key): the ID of the time activity code to edit
     * TimeActivityCodeDSC (string): describes what the time activity code covers,
     * TimeActivityRateNBR (number, nullable): the pay rate tied to this code,
     * TimeTaskCodeID (number, foreign key): the ID of the Task Code this Activity Code is tied to, 
     * PTOTypeID (number, foreign key): the ID of the PTO category this activity code falls under.
     * @returns the newly created time activity code.
     */
    async editTimeActivityCode(timeActivityCodeObj) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/activity-code`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(timeActivityCodeObj)
        }).then(response => response.json());
    }
}