//Takes in two date strings (start and end) with format YYYY-MM-DD and two time strings (start and end) 
//with format HH:MM to find the difference in hours between the start and end.
export const hourDifference = (dateStart, timeStart, dateEnd, timeEnd) => {
    const HOUR_CONVERTER = 3600000
    const start = new Date(dateStart.concat(" ", timeStart).replace(/-/g, "/"));
    const end = new Date(dateEnd.concat(" ", timeEnd).replace(/-/g, "/"));
    return (end - start)/HOUR_CONVERTER;
}

//Combines and Converts a date string with format: YYYY-MM-DD and a time string with format: HH:MM
//into a Date Time Stamp the database can accept.
export const dateTimePickerToDB = (datePicked, timePicked) => {
    const dateAndTime = datePicked.concat("T", timePicked);
    const currentDate = new Date(dateAndTime);
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const monthZero = month < 10 ? "0" : "";
    const day = currentDate.getDate();
    const dayZero = day < 10 ? "0" : "";
    const hour = currentDate.getHours();
    const hourZero = hour < 10 ? "0" : "";
    const minutes = currentDate.getMinutes();
    const minutesZero = minutes < 10 ? "0" : "";
    return `${year}-${monthZero}${month}-${dayZero}${day} ${hourZero}${hour}:${minutesZero}${minutes} -06:00`;
}

//Converts date string with format: YYYY-MM-DD into string with format: MM/DD/YYYY
export const pickerDateToTable = (pickerDate) => {
    const year = pickerDate.slice(0,4);
    const month = pickerDate.slice(5,7);
    const day = pickerDate.slice(8,10);
    return `${month}/${day}/${year}`;
}

//Takes a string representing a date in format HH:MM where HH is between 00 and 23
//and returns HH:MM AM format.
export const pickerTimeToTable = (time) => {
    let hours = parseInt(time.slice(0,2));
    let minutes = parseInt(time.slice(3,5));
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0'+minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
}

/**
 * function dateAndTimeToDB
 * @param {string} datePickerInput: a string generated by input type date
 * @param {string} timePickerInput: a string generated by input type time
 * @returns a string that represents the date and time that can be stored in the database
 */
 export const dateAndTimeToDB = (datePickerInput, timePickerInput) => {
    const dateAndTime = datePickerInput.concat("T", timePickerInput);
    const currentDate = new Date(dateAndTime);
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const monthZero = month < 10 ? "0" : "";
    const day = currentDate.getDate();
    const dayZero = day < 10 ? "0" : "";
    const hour = currentDate.getHours();
    const hourZero = hour < 10 ? "0" : "";
    const minutes = currentDate.getMinutes();
    const minutesZero = minutes < 10 ? "0" : "";
    return `${year}-${monthZero}${month}-${dayZero}${day} ${hourZero}${hour}:${minutesZero}${minutes} -06:00`;
 }

 export const currentDateToDB = () => {
     const currentDate = new Date();
     const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const monthZero = month < 10 ? "0" : "";
    const day = currentDate.getDate();
    const dayZero = day < 10 ? "0" : "";
    const hour = currentDate.getHours();
    const hourZero = hour < 10 ? "0" : "";
    const minutes = currentDate.getMinutes();
    const minutesZero = minutes < 10 ? "0" : "";
    return `${year}-${monthZero}${month}-${dayZero}${day} ${hourZero}${hour}:${minutesZero}${minutes} -06:00`;
 }

export const timeToMilitaryTime = (hour, minute, period) => {
    let tempHour = hour === "12" ? "00" : hour;
    return `${period === "PM" ? parseInt(tempHour) + 12 : tempHour}:${minute}`;
}