import {getIDToken} from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class PersonApi {
    /**
     * method getPersonByEmail
     * @param {string} email
     * @returns a promise of a person object with matching email address or no one if that email address doesn't exist.
     */
    async getPersonByEmail(email) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/person/email/${email}`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method getAllEmployees
     * @returns a promised array of all employees that are active
     */
    async getAllEmployees() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/person`, {
            headers: headers
        }).then(response => response.json());
    }

    //Return a promised array of all employees supervised by the requested supervisor.
    async getSupervisedEmployees(SupervisorPersonID) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/person/employee/supervisor/${SupervisorPersonID}`, {
            headers: headers
        }).then(response => response.json());
    }

    //Return a promised array of all employees supervised by the requested supervisor.
    async getAllSupervisedEmployees() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/person/employee/supervisors`, {
            headers: headers
        }).then(response => response.json());
    }

    //Return a promised array of all supervisors and admins.
    async getSupervisors() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/person/supervisor`, {
            headers: headers
        }).then(response => response.json());
    }

    //Return a promised array of all admins.
    async getAdmins() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/person/supervisor`, {
            headers: headers
        }).then(response => response.json());
    }

    async addEmployee(employeeObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/person/employee`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(employeeObject)
        }).then(response => response.json());
    }

    /**
     *
     * @param {object} employeeObject- PersonID: The Primary Key ID of the employee to edit. FirstNM: The first name of the employee. LastNM: The last Name of the employee.
     * EmailTXT: The User Principal Name, which follows an email format. TimeCardPersonTypeID: The ID attached to their role in the time clock app (Admin, Supervisor, or Employee).
     * EmployeeTypeDSC: Full Time, Part Time, Intern //Todo: will need to adjust this to match whatever the database setup is for this value.
     * EmployeePayrollCodeNM: The code used to process payroll for this employee. Defaults to the first four letters of their last name + the first 4 letters of their first name, all caps.
     * HoursPerWeekMaxNBR: The allotted hours given to the user per week. If they go over, notify the user and their supervisor.
     * SupervisorPersonID: The person that supervises this employee. AssignedProgramID: the program the employee works in. Reference the already setup App.Program table instead of the App.AssignedProgram.
     * ExemptFLG: A bit that represents whether the employee is salaried or not.
     * @returns the newly edited employee.
     */
    async editEmployee(employeeObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/person/employee`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(employeeObject)
        }).then(response => response.json());
    }
}