import React, {useEffect, useState} from 'react';
import './drop-down.css';    //CSS critical for dropdown's functionality

/**
 * React functional component Dropdown
 * @param items: An array of objects to be the different options.
 * @param itemNameKey: the Key of the item name that will act as the name of the option.
 * @param itemNameDivider: a string that can be added between the two item name keys to allow more customization. Will default to a single space if excluded. 
 * @param itemNameSecondKey: another key in case the dropdown needs a first and last name of a person.
 * @param selected: the currently selected value
 * @param setSelected: a state change function to update the value of selected.
 * @param label: a string that displays on the dropdown.
 * @param isBig: a boolean describing whether the styling of the dropdown is big or not
 * @param selectAll: a boolean describing whether or not a Select All option is included
 * @returns a dropdown component
 */
export default function ObjectDropdown({ items, itemNameKey, itemNameDivider, itemNameSecondKey, dataKey, dataKey2, subDataKey, selected, setSelected, setSelectedWithDataKey, setSelectedWithDataKeyImpactsOtherField, label, isBig, selectAll, searchable, dateRange=false, dateRangeSelected=false, setDateRangeSelected}) {
    const [filteredItems, setFilteredItems] = useState(items ? items : []);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        if(searchQuery !== "") {
            const filterDownOptions = items.filter((option) => {
                if(option[itemNameKey]?.toLowerCase().includes(searchQuery.toLowerCase())) {
                    return true;
                } else if(option[itemNameSecondKey]?.toLowerCase().includes(searchQuery.toLowerCase())) {
                    return true;
                } else {
                    return false;
                }
            });
            setFilteredItems(filterDownOptions);
        } else {
            setFilteredItems(items);
        }
    }, [items, itemNameKey, itemNameSecondKey, searchQuery]);

    const handleSelected = (index) => {
        if (dateRange)
            setDateRangeSelected(false)
        if(setSelectedWithDataKeyImpactsOtherField) {
            setSelectedWithDataKeyImpactsOtherField(filteredItems[index], dataKey, dataKey2, subDataKey);
        }
        if(setSelectedWithDataKey) {
            setSelectedWithDataKey(filteredItems[index], dataKey);
        } else if(setSelected) {
            setSelected(filteredItems[index]);
        }
    }

    //Generates all dropdown items
    const dropdownItems = filteredItems.map((item, index) => {
        const optionString = itemNameSecondKey && itemNameDivider ? 
            item[itemNameKey] + itemNameDivider + item[itemNameSecondKey] 
            : itemNameSecondKey ? item[itemNameKey] + " " + item[itemNameSecondKey] 
            : item[itemNameKey];
        return (
            <button type="button" key={index} value={index} className="dropdown-item" 
                onClick={({target}) => handleSelected(target.value)}
            >
                {optionString}
            </button>
        )
    });

    const displaySelected = selected && itemNameSecondKey && itemNameDivider ?
        selected[itemNameKey] + itemNameDivider + selected[itemNameSecondKey]
        : selected && itemNameSecondKey ? selected[itemNameKey] + " " + selected[itemNameSecondKey]
        : selected ? selected[itemNameKey]
        : selected === null && dateRangeSelected ? "Custom Date Range"
        : selected === null ? "All " + label + "s"
        : label;

    const style = isBig ? 
        "btn btn-outline-dark dropdown-toggle" 
        : "btn btn-outline-dark dropdown-toggle btn-sm";

    const selectAllOption = selectAll ? 
        <button type="button" className="dropdown-item" onClick={() => {
            setSelected(null);
            if (dateRange)
                setDateRangeSelected(false)
        }}>All {label}s</button>
        : null

    const dateRangeOption = dateRange ?
    <button type="button" className="dropdown-item" onClick={() => {
        setSelected(null);
        setDateRangeSelected(true);
    }}>Custom Date Range</button>
    : null

    const search = searchable ? 
        <input 
            className="form-control" 
            placeholder="search" 
            value={searchQuery} 
            onChange={({target}) => setSearchQuery(target.value)}
        /> 
        : null

    return (
        <div className="dropdown">
            <button className={ style }
                type="button" id="dropdownMenuButton" data-toggle="dropdown" 
                aria-haspopup="true" aria-expanded="false"
            >
                {displaySelected}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {search}
                {selectAllOption}
                {dateRangeOption}
                {dropdownItems}
            </div>
        </div>
    )
}
