import React from 'react';
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "./embedded-bi.css";
import {useAuth} from "../../context/auth-context";
const  {REACT_APP_POWER_BI_REPORT_ID} = process.env;

export default function EmbeddedBi({accessToken}) {
    const {isPowerBIAdmin} = useAuth();

    if(isPowerBIAdmin()) {
        return (
            <div>
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report',
                        id: REACT_APP_POWER_BI_REPORT_ID,
                        accessToken: accessToken,
                        tokenType: models.TokenType.Aad,
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: true
                                }
                            },
                            background: models.BackgroundType.Transparent,
                        }
                    }}

                    eventHandlers = { 
                        new Map([
                            ['loaded', function () {console.log('Report loaded');}],
                            ['rendered', function () {console.log('Report rendered');}],
                            ['error', function (event) {
                                console.log(event.detail);
                            }]
                        ])
	                }
                    cssClassName={"chart-area-selected"}
                />
            </div>
        )
    } else {
        return (
            <div>
                Non Admin dashboards coming soon.
            </div>
        )
    }
}
