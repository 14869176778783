export const userPayCode = (firstName, lastName) => {
    const MAX_CHAR_USED = 4;
    let result = "";
    if(lastName.length > MAX_CHAR_USED) {
        result = result.concat(lastName.substring(0,4));
    } else {
        result = result.concat(lastName);
    }

    if(firstName.length > MAX_CHAR_USED) {
        result = result.concat(firstName.substring(0,4));
    } else {
        result = result.concat(firstName);
    }

    return result.toUpperCase();
}


export const arrayToStringListNoAnd = (arr) => {
    let stringList = arr.join(`, `);
    return stringList;
}