import React, {useEffect, useState} from 'react';

export default function TimeStatus({totalHours, weekHours, weekMaxHours, showDateRange, overtime, showPayPeriod}) {
    const [alertStyle, setAlertStyle] = useState("alert alert-primary");
    const [displayedLabel, setDisplayedLabel] = useState(null);

    useEffect(() => {
        if(overtime > 0) {
            setAlertStyle("alert alert-danger");
        } else {
            setAlertStyle("alert alert-primary");
        }
    }, [overtime]);

    useEffect(() => {
        if (showDateRange) {
            setDisplayedLabel("Custom Date Range Hours: ");
        } else if (showPayPeriod) {
            setDisplayedLabel("Pay Period Hours: ");
        } else {
            // show label that says all hours
            setDisplayedLabel("All Hours: ");
        }
    }, [showDateRange, totalHours, showPayPeriod]);

    return (
        <div className={alertStyle}>
            <div className="row">
                <div className="col">
                    <span>{displayedLabel}{totalHours}</span>
                </div>
                <div className="col">
                    <span>This Week's Hours: {weekHours}/{weekMaxHours}</span>
                </div>
            </div>
        </div>
    )
}
