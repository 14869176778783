import React, {useState} from 'react';
import TaskCodes from '../task-codes/task-codes';
import ActivityCodes from '../activity-codes/activity-codes';
import DownloadReports from '../download-reports/download-reports';
import LockPayPeriod from '../lock-pay-period/lock-pay-period';
import AdminEditTime from '../admin-edit-time/admin-edit-time';
import ManageEmployees from '../manage-employees/manage-employees';
import ViewOvertime from '../overtime/view-overtime';

export default function Admin() {
    const [currentView, setCurrentView] = useState("edit-time");
    
    const pageToDisplay = currentView === "edit-time" ? <AdminEditTime/>
        : currentView === "reports" ? <DownloadReports/>
        : currentView === "lock" ? <LockPayPeriod/>
        : currentView === "overtime" ? <ViewOvertime/>
        : currentView === "users" ? <ManageEmployees/>
        : currentView === "activity-codes" ? <ActivityCodes/>
        : <TaskCodes/>;

    const activeTab = "nav-link adm-link active";
    const passiveTab = "nav-link adm-link";
    return (
        <div className="container-fluid">
            <ul className="nav nav-tabs mt-2 mb-2" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className={currentView === "edit-time" ? activeTab : passiveTab} onClick={() => setCurrentView("edit-time")}>
                        EDIT AND APPROVE TIME
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={currentView === "reports" ? activeTab : passiveTab} onClick={() => setCurrentView("reports")}>
                        DOWNLOAD REPORTS
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={currentView === "lock" ? activeTab : passiveTab} onClick={() => setCurrentView("lock")}>
                        LOCK PAY PERIOD
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={currentView === "overtime" ? activeTab : passiveTab} onClick={() => setCurrentView("overtime")}>
                        OVERTIME REVIEW
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={currentView === "users" ? activeTab : passiveTab} onClick={() => setCurrentView("users")}>
                        MANAGE USERS
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={currentView === "activity-codes" ? activeTab : passiveTab} onClick={() => setCurrentView("activity-codes")}>
                        ACTIVITY CODES
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={currentView === "task-codes" ? activeTab : passiveTab} onClick={() => setCurrentView("task-codes")}>
                        TASK CODES
                    </button>
                </li>
            </ul>
                {pageToDisplay}
        </div>
    )
}
