import React from 'react';
import EmployeeTable from './employee-table';

export default function ManageEmployees() {
    return (
        <>
            <EmployeeTable/>
        </>
    )
}
