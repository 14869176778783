import React, {useEffect, useState} from 'react';
import { ObjectDropdown, Table } from "../../shared/components";
import { PayPeriodApi, PayrollApi } from '../../shared/services';
import CsvDownloader from 'react-csv-downloader';

export default function DownloadReports() {
    const [payPeriod, setPayPeriod] = useState(null);
    const [payPeriods, setPayPeriods] = useState([]);
    const [payroll, setPayroll] = useState([]);

    useEffect(() => {
        const myPayPeriodApi = new PayPeriodApi();
        myPayPeriodApi.getPayPeriods()
            .then(payPeriodList => {
                setPayPeriods(payPeriodList);
            });
    }, []);

    useEffect(() => {
        if(payPeriod) {
            const myPayrollApi = new PayrollApi();
            myPayrollApi.getPayrollForPayPeriod(payPeriod.PayPeriodID)
                .then(payrollList => {
                    setPayroll(payrollList);
                })
        }
    }, [payPeriod]);

    const CSVheaders = [
        {displayName: 'payroll code', id: 'EmployeePayrollCodeNM'},
        {displayName: 'pto type', id: 'PTOTypeDSC'},
        {displayName: 'hours', id: 'Hours'},
        {displayName: 'rate', id: 'TimeActivityRateNBR'},
        {displayName: 'blank1', id: 'Blank1'},
        {displayName: 'blank2', id: 'Blank2'},
        {displayName: 'employee type code', id: 'TaskCodeID'}
    ];

    const columns = [
        {
            header: 'PAYROLL CODE', 
            dataKey: 'EmployeePayrollCodeNM',
            editable: false,
            type: "string"
        },
        {
            header: 'PTO TYPE',
            dataKey: 'PTOTypeDSC',
            editable: false,
            type: "string"
        },
        {
            header: 'HOURS', 
            dataKey: 'Hours',
            editable: false,
            type: "number",
        },    
        {
            header: 'RATE', 
            dataKey: 'TimeActivityRateNBR',
            editable: false,
            type: "number"
        },
        {
            header: "EMPLOYEE TYPE CODE",
            dataKey: "TaskCodeID",
            editable: false,
            type: 'string'
        }
    ]

    const fileName = payPeriod ? `payroll_report${payPeriod.PayPeriodEndDTSPretty}` : "payroll_report";

    return (
        <div className="mx-4 mt-2">
            <h5 className="title">Download Time Sheet Report</h5>
            <div className="row">
                <div className="col col-lg-2">
                    <ObjectDropdown
                        items={payPeriods}
                        itemNameKey="PayPeriodStartDTSPretty"
                        itemNameDivider=" - "
                        itemNameSecondKey="PayPeriodEndDTSPretty"
                        selected={payPeriod}
                        setSelected={setPayPeriod}
                        label="Pay Period"
                        isBig={true}
                        searchable={true}
                    />
                </div>
                <div className="col col-lg-2 download-container2">
                    <CsvDownloader columns={CSVheaders} datas={payroll} filename={fileName} separator="," bom={false} noHeader={true} disabled={payPeriod === null}>
                        <button className="btn btn-primary" disabled={payPeriod === null}>Download Report</button>
                    </CsvDownloader>
                </div>
            </div>
            <Table
                columns={columns}
                rowsData={payroll}
                primaryKey="primaryKey"
                scrollable={true}
                height="600"
            />
        </div>
    )
}
