import React, { useEffect, useState } from 'react';
import { Table, Alert } from '../../shared/components';
import { OvertimeApi, TimePunchApi } from '../../shared/services';
import { dateAndTimeToDB, timeToMilitaryTime } from '../../utils/date-format';
import { useAuth } from '../../context/auth-context';
import CsvDownloader from 'react-csv-downloader';

export default function TimePunchTable({ timePunches, setTimePunches, timeActivityCodes, employee, employeeID, payPeriod, weekStart, unapprovedPunches, canApprove, allowDelete, downloadable, dateRange }) {
    const [displayAlert, setDisplayAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertStyle, setAlertStyle] = useState("danger");
    const {isAdmin, currentUser} = useAuth();

    useEffect(() => {
        const myTimePunchApi = new TimePunchApi();
        if(employee && payPeriod) {
            myTimePunchApi.getEmployeeTimePunchesByPeriod(payPeriod.PayPeriodID, employee.PersonID)
                .then(timePunchList => {
                    setTimePunches(timePunchList || []);
                });
        } else if(employee) {
            if (dateRange) {
                myTimePunchApi.getEmployeeDRTimePunches(employee.PersonID, dateRange.start, dateRange.end)
                    .then(timePunchList => {
                        setTimePunches(timePunchList || []);
                    })
            } else {
                myTimePunchApi.getEmployeeTimePunches(employee.PersonID)
                    .then(timePunchList => {
                        setTimePunches(timePunchList || []);
                    })
            }
        } else if(employeeID && weekStart) {
            const myOvertimeApi = new OvertimeApi();
            myOvertimeApi.getEmployeeOvertimePunches(employeeID, weekStart)
                .then(timePunchList => {
                    setTimePunches(timePunchList);
                })
        } else if(unapprovedPunches) {
            setTimePunches(unapprovedPunches);
        }
    }, [employee, employeeID, payPeriod, weekStart, unapprovedPunches, setTimePunches, dateRange]);

    const handleEdit = (data) => {
        //If the edit is done by the person tied to the time punch and they are not an admin
        //, unapprove the punch so the supervisor has to look it again
        if(currentUser.PersonID === data.PersonID && !isAdmin()) {
            data.TimePunchApprovedFLG = false;
        }
        //Lift up foreign key values to parent level from their inner objects
        data.TimeActivityCodeID = data.TimeActivityCode?.TimeActivityCodeID;
        //Update the DTSs using the separate date and time fields
        data.InTime = timeToMilitaryTime(data.inHour, data.inMinute, data.inPeriod);
        data.OutTime = timeToMilitaryTime(data.outHour, data.outMinute, data.outPeriod);
        data.TimeInDTS = dateAndTimeToDB(data.InDate, data.InTime);
        data.TimeOutDTS = dateAndTimeToDB(data.OutDate, data.OutTime);

        //Make the edit through the server
        const myTimePunchApi = new TimePunchApi();
        myTimePunchApi.editTimePunch(data)
            .then(editedTimePunch => {
                if(editedTimePunch.error) {
                    setAlertStyle("danger");
                    setAlertMessage(`Editing the time punch failed due to the following error: ${editedTimePunch.error}`);
                    setDisplayAlert(true);
                } else {
                    //Update the state of the Time Punch array
                    const timePunchesCopy = [...timePunches];
                    timePunchesCopy.forEach((timePunchCopy, index) => {
                        if(timePunchCopy.TimePunchID === editedTimePunch.TimePunchID) {
                            timePunchesCopy[index] = data;
                        }
                    });
                    setTimePunches(timePunchesCopy);
                }
            });
    }

    const handleDelete = (TimePunchID) => {
        const myTimePunchApi = new TimePunchApi();
        myTimePunchApi.deleteTimePunch(TimePunchID)
            .then(deleteMessage => {
                //If an error message was sent notify the user the delete didn't work.
                if(deleteMessage.error) {
                    setAlertStyle("danger");
                    setAlertMessage(`Deleting the time punch failed due to the following error: ${deleteMessage.error}`);
                    setDisplayAlert(true);
                //Otherwise, remove the same time punch from the frontend
                } else {
                    const timePunchesCopy = [...timePunches];
                    timePunchesCopy.forEach((timePunchCopy, index) => {
                        if(timePunchCopy.TimePunchID === TimePunchID) {
                            timePunchesCopy.splice(index, 1);
                        }
                    });
                    setTimePunches(timePunchesCopy);
                }
            });
    }

    const disableEditButton = payPeriod?.PayPeriodLockedFLG && !isAdmin() ? payPeriod.PayPeriodLockedFLG : false;

    const CSVheaders = [
        {displayName: "First Name", id: "FirstNM"},
        {displayName: "Last Name", id: "LastNM"},
        {displayName: "Date In", id: "InDate"},
        {displayName: "Time In", id: "InTime"},
        {displayName: "Date Out", id: "OutDate"},
        {displayName: "Time Out", id: "OutTime"},
        {displayName: "Hours", id:"hours"},
        {displayName: "Activity Code", id: "TimeActivityCodeDSC"}
    ]

    const columns = [
        {
            header: "FIRST NAME",
            dataKey: "FirstNM",
            editable: false,
            type: "string"
        },
        {
            header: "LAST NAME",
            dataKey: "LastNM",
            editable: false,
            type: "string"
        },
        {
            header: "DATE IN",
            dataKey: "InDate",
            editable: true,
            type: "date",
            alsoEdit: "OutDate"
        },
        {
            header: "TIME IN",
            dataKey: "InTime",
            hourKey: "inHour",
            minuteKey: "inMinute",
            periodKey: "inPeriod",
            editable: true,
            type: "time",
            step: "900"
        },
        {
            header: "DATE OUT",
            dataKey: "OutDate",
            editable: true,
            type: "date"
        },
        {
            header: "TIME OUT",
            dataKey: "OutTime",
            hourKey: "outHour",
            minuteKey: "outMinute",
            periodKey: "outPeriod",
            editable: true,
            type: "time"
        },
        {
            header: "HOURS",
            dataKey: "hours",
            editable: false,
            type: "date-time-compare",
            dateCompare1: "InDate",
            timeCompare1: "InTime",
            hourCompare1: "inHour",
            minuteCompare1: "inMinute",
            periodCompare1: "inPeriod",
            dateCompare2: "OutDate",
            timeCompare2: "OutTime",
            hourCompare2: "outHour",
            minuteCompare2: "outMinute",
            periodCompare2: "outPeriod",
        },
        {
            header: "ACTIVITY CODE",
            dataKey: "TimeActivityCode",
            editable: unapprovedPunches ? false : true,
            type: "object-dropdown",
            itemNameKey: "TimeActivityCodeDSC",
            options: timeActivityCodes,
            label: "Activity Code"
        },
        {
            header: "EDIT", 
            dataKey: "TimeStampID", 
            editable: false, 
            disabled: disableEditButton,
            type: "edit-button",
            function: handleEdit
        }
    ];
    //Add an approve column if requested
    if(canApprove) {
        columns.push({
            header: "APPROVE",
            dataKey: "TimePunchApprovedFLG",
            editable: true,
            type: "boolean"
        });
    }

    //Add a delete column if requested
    if(allowDelete) {
        columns.push({
            header: "DELETE",
            dataKey: "deleteButton",
            primaryKey: "TimePunchID",
            editable: false,
            type: "delete-button",
            function: handleDelete
        });
    }

    const downloadFileName = `${employee?.LastNM} ${payPeriod ? payPeriod.PayPeriodStartDTSPretty : "all-pay-periods"}`

    const downloadPunches = downloadable ?
        <div className="download-container">
            <CsvDownloader columns={CSVheaders} datas={timePunches} separator="," bom={false} filename={downloadFileName}>
                <button className="btn btn-primary">Download Report</button>
            </CsvDownloader>
        </div>
        : null;

    return (
        <>
            <Alert
                displayAlert={displayAlert}
                setDisplayAlert={setDisplayAlert}
                style={alertStyle}
                message={alertMessage}
            />
            {downloadPunches}
            <Table 
                columns={columns}
                rowsData={timePunches}
                isAdd={false}
                primaryKey="TimePunchID"
                height={unapprovedPunches ? "500" :"400"}
            />
        </>
    )
}
