import {getIDToken} from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class PayPeriodApi {
    /**
     * method getPayPeriods
     * @returns a promised array of all pay periods
     */
    async getPayPeriods() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        // add a 
        return fetch(`${REACT_APP_API_URL}/api/pay-period`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method toggleLock
     * @param {object} payPeriodObj- PayPeriodID: the ID of the pay period to edit
     * PayPeriodLockedFLG: 1 if locking the pay period, 0 if unlocking
     * @returns the edited pay period
     */
    async toggleLock(payPeriodObj) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/pay-period/lock`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(payPeriodObj)
        }).then(response => response.json());
    }
}